import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/app-check";
import "firebase/compat/analytics";

import EnvUtils from "../misc/env";

const firebaseConfig = {
	apiKey: EnvUtils.FIREBASE_API_KEY,
	authDomain: EnvUtils.FIREBASE_AUTH_DOMAIN,
	projectId: EnvUtils.FIREBASE_PROJECT_ID,
	storageBucket: EnvUtils.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: EnvUtils.FIREBASE_MESSAGING_SENDER_ID,
	appId: EnvUtils.FIREBASE_APP_ID,
	// measurementId: EnvUtils.FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

const siteKey = EnvUtils.SITEKEY;

// Get debug key for App Check
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
	siteKey,

	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	true
);

// init firebase services
const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();
const projectStorage = firebase.storage();
const projectFunctions = firebase.app().functions(EnvUtils.FIREBASE_REGION);
const projectAnalytics = firebase.analytics();

// UNCOMMENT TO TEST CLOUD FUNCTIONS LOCALLY
// if (location.hostname.includes("localhost")) {
//   projectFunctions.useEmulator("localhost", 5001);
// projectStorage.useEmulator("localhost", 9199);
// }

// UNCOMMENT TO TEST EVERYTHING LOCALLY
// if (location.hostname.includes("localhost")) {
//     // projectAuth.useEmulator("http://localhost:9099");
//     projectFirestore.useEmulator("localhost", 8080);
//     // projectStorage.useEmulator("localhost", 9199);
//     projectFunctions.useEmulator("localhost", 5001);

//     projectFirestore.settings({
//         experimentalForceLongPolling: true,
//         ssl: false,
//         host: "localhost:8080",
//     });
// }

projectFirestore.settings({
	experimentalAutoDetectLongPolling: true,
	merge: true,
});

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const timestampFromDate = firebase.firestore.Timestamp.fromDate;

// array
const addArrayItem = firebase.firestore.FieldValue.arrayUnion;
const removeArrayItem = firebase.firestore.FieldValue.arrayRemove;

export {
	appCheck,
	projectFunctions,
	projectAuth,
	projectFirestore,
	projectStorage,
	projectAnalytics,
	timestamp,
	timestampFromDate,
	addArrayItem,
	removeArrayItem,
};
