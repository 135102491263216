import { projectFirestore } from "../firebase/config";
import { ref } from "@vue/reactivity";

const invitationCollection = () => {
	const invitationExist = ref(false);
	const invitationId = ref(null);
	const invitation = ref(null);

	const checkIfInviteExist = async (email) => {
		const invitationsData = await projectFirestore
			.collection("invitations")
			.where("employeeEmail", "==", email)
			.get();

		var count = invitationsData.docs.length;

		if (count == 0) {
			invitationExist.value = false;
		} else {
			console.log("invitation exists");
			invitationExist.value = true;
			invitationId.value = invitationsData.docs[0].id;
			invitation.value = invitationsData.docs[0].data();
		}
	};

	const checkIfPendingInviteExist = async (email) => {
		// The default response
		let result = {
			invitationId: null,
			error: null,
		};

		// If exists, get pending invitation associated with the email
		let invitation;

		try {
			const invitationRef = projectFirestore
				.collection("invitations")
				.where("employeeEmail", "==", email)
				.where("status", "==", "Pending");

			invitation = await invitationRef.get();
		} catch (e) {
			// If there's an error, pass that to the login form
			console.error("Failed to fetch firebase records: ", e);

			result = {
				invitationId: null,
				error: "Server error. Please try again later.",
			};

			return result;
		}

		if (invitation.docs.length > 0) {
			// Return the invitation id to handle redirects for onboarding
			result = {
				invitationId: invitation.docs[0].id,
				error: null,
			};
			return result;
		} else {
			// Return the default response
			return result;
		}
	};

	const rejectInvitation = async (invitationId) => {
		try {
			await projectFirestore
				.collection("invitations")
				.doc(invitationId)
				.delete();
		} catch (e) {
			console.error("Failed to reject invitation: ", e);
		}
	};

	return {
		invitationExist,
		invitationId,
		invitation,
		checkIfInviteExist,
		checkIfPendingInviteExist,
		rejectInvitation,
	};
};

export default invitationCollection;
