import { ref } from "@vue/reactivity";
import { projectAuth } from "../firebase/config";
import useCookies from "./useCookies";

const error = ref(null);

const logout = async () => {
	const { setCookie } = useCookies();
	error.value = null;

	try {
		await projectAuth.signOut();
		setCookie("rm_cId", "", "-1");
	} catch (err) {
		console.log("Logout error message", err.message);
		error.value =
			"Error! Could not log out. Check your internet connection and try again";
	}
};

const useLogout = () => {
	return { logout, error };
};

export default useLogout;
