import { projectFirestore } from "../firebase/config";
import getCollection from "./getCollection";
import useCookies from "./useCookies";
import { ref } from "vue";
const locationCollection = () => {
	const { getCookie } = useCookies();
	const locations = ref(null);
	const error = ref("");
	const companyId = getCookie("rm_cId");

	const getLocation = async () => {
		try {
			let collectionRef = projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("locations");

			const { documents: items } = getCollection(collectionRef);
			locations.value = items;
		} catch (err) {
			error.value = err.message;
		}
	};

	const getFirstLocation = async () => {
		try {
			const collectionRef = projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("locations")
				.orderBy("createdAt", "asc")
				.limit(1);

			const snapshot = await collectionRef.get();

			if (!snapshot.empty) {
				return snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}))[0];
			} else {
				throw new Error("No locations found");
			}
		} catch (err) {
			throw err;
		}
	};

	return { getLocation, getFirstLocation, locations, error };
};

export default locationCollection;
