<template>
	<!-- Error warnings from useSignup -->
	<Alert :message="error" v-if="error" />

	<!-- Prevent invited team members from using this form. Includes link to invitation -->
	<transition name="fade" mode="out-in">
		<Popup v-if="popup">
			<template #modal-header-content>
				<div class="left">
					<div class="modal-title">
						<h3>You have a pending Risk Memo invitation</h3>
					</div>
				</div>
				<div class="right">
					<div class="btn btn-icon" @click="closePopup">
						<div class="text">Close</div>
						<fa icon="times" />
					</div>
				</div>
			</template>
			<template #modal-body-content>
				<p>
					{{ invitation.employerName }} from
					{{ invitation.companyName }} has invited you to join their
					company account.
				</p>
				<br />
				<p>
					To reject this invitation and create a new company account,
					<a href="mailto:support@riskmemo.com">contact support</a>.
					To accept, click the link below.
				</p>
				<br />
				<br />
				<a class="btn btn-success" :href="inviteUrl"
					>Accept Invitation</a
				>
			</template>
		</Popup>
	</transition>

	<transition name="fade" mode="out-in">
		<Popup v-if="showTerms" class="model-container">
			<template #modal-header-content>
				<div class="left">
					<div class="modal-title">
						<h3>Terms of Sale and Privacy Policy</h3>
					</div>
				</div>
			</template>
			<template #modal-body-content>
				<p>
					<b>
						By using our services, you agree to our

						<a
							href="https://riskmemo.com/terms/terms-of-sale"
							target="new"
							>Terms of Sale</a
						>
						and

						<a
							href="https://riskmemo.com/terms/privacy-policy"
							target="new"
							>Privacy Policy</a
						>
						.</b
					>
				</p>
				<br />
				<p>
					These outline our subscription terms, payment methods, data
					usage, and your privacy rights. We ensure your data is used
					responsibly and provide details on data collection, usage,
					and protection. Please review these documents for full
					details.
				</p>
				<br />
				<br />
				<div class="split">
					<div class="flex-centered">
						<button class="btn btn-success" @click="handleSubmit">
							Accept
						</button>
					</div>
					<div class="flex-centered">
						<button class="btn btn-danger" @click="closeTermsPopup">
							Decline
						</button>
					</div>
				</div>
			</template>
		</Popup>
	</transition>

	<!-- Page Content -->
	<div class="container">
		<div class="container-left">
			<div class="vertical-center">
				<div class="left-text">
					<h1>Get started <span class="accent">for free</span></h1>
					<div class="spacer-20"></div>
					<p>
						Play around with it first. Pay and add your team later.
					</p>

					<div class="spacer-20"></div>

					<div class="steps">
						<div class="steps-spacer"></div>
						<h3><fa icon="square" />&nbsp;Company details</h3>
					</div>
					<div class="spacer-20"></div>

					<div class="steps">
						<div class="steps-spacer"></div>
						<h3><fa icon="square" />&nbsp;Verify email</h3>
					</div>
				</div>
			</div>
			<div class="login-link">
				<p>
					Already have, or been invited to join an existing account?
					<router-link to="/login">Login here</router-link>.
				</p>
			</div>
		</div>

		<!-- Right form -->
		<div class="container-right">
			<div class="vertical-center">
				<div class="form-container-right">
					<div class="logo">
						<img
							class="img-responsive"
							src="../../assets/rm-logo-white-blue@1080px.png"
							alt="Risk Memo Logo"
						/>
					</div>
					<div class="tile loading-tile" v-if="isLoading">
						<PageLoading />
					</div>

					<!-- Sign Up with Email -->
					<form
						class="rm-form tile"
						@submit.prevent="handleTermsClick()"
						v-if="!isLoading"
					>
						<div class="p-1rem">
							<div class="form-input">
								<label>Full Name:</label>
								<input
									type="text"
									required
									v-model="fullName"
								/>
							</div>

							<div class="form-input">
								<label>Email Address:</label>
								<input type="email" required v-model="email" />
							</div>

							<div class="form-input">
								<label>Create a Strong Password:</label>
								<input
									type="password"
									required
									v-model="password"
									@keyup="checkPassword"
								/>
								<div class="password-hints" v-if="showChecks">
									<p :class="{ success: checks.length }">
										At least 8 characters long
									</p>
									<p :class="{ success: checks.uppercase }">
										Contains uppercase
									</p>
									<p :class="{ success: checks.lowercase }">
										Contains lowercase
									</p>
									<p :class="{ success: checks.digit }">
										Contains number
									</p>
									<p :class="{ success: checks.specialChar }">
										Contains special character
									</p>
								</div>
							</div>
						</div>

						<div class="form-submit">
							<div v-if="isLoading">
								<page-loading />
							</div>
							<button
								class="btn btn-primary"
								type="submit"
								value="submit"
								v-else
							>
								Sign up
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useSignup from "../../composables/useSignup";
import { projectAuth } from "../../firebase/config";
import Alert from "../../components/Alert";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";
import { event } from "vue-gtag";
import invitationCollection from "../../composables/invitationCollection";
import Popup from "../../components/Popup.vue";
import { useSessionStore } from "../../stores/sessionStore";
import { useChecklistsStore } from "../../stores/checklistsStore";
import { onMounted } from "vue";
import getUser from "@/composables/getUser";

export default {
	name: "Signup",
	components: { Alert, PageLoading, Popup },
	setup() {
		useMeta({
			title: "Signup",
			description:
				"Sign up for free to the Risk Memo Checklist and Equipment Management App.",
			link: [
				{ rel: "canonical", href: "https://app.riskmemo.com/signup" },
			],
		});
		const { error, isLoading, signup } = useSignup();
		const sessionStore = useSessionStore();
		const checklistsStore = useChecklistsStore();
		const {
			invitation,
			invitationId,
			invitationExist,
			checkIfInviteExist,
		} = invitationCollection();
		const router = useRouter();
		const { user } = getUser();

		// refs
		const email = ref("");

		const fullName = ref("");
		const password = ref("");

		const showChecks = ref(false);
		const checks = ref({
			length: false,
			uppercase: false,
			lowercase: false,
			digit: false,
			specialChar: false,
		});

		const popup = ref(false);
		const showTerms = ref(false);

		const inviteUrl = ref("");

		onMounted(() => {
			sessionStore.endSession();
			checklistsStore.resetChecklistsStore();
		});

		const checkPassword = () => {
			showChecks.value = true;
			checks.value = {
				length: password.value.length >= 8,
				uppercase: /[A-Z]/.test(password.value),
				lowercase: /[a-z]/.test(password.value),
				digit: /[0-9]/.test(password.value),
				specialChar: /[!@#$%^&*]/.test(password.value),
			};
		};

		const handleTermsClick = () => {
			showTerms.value = true;
		};

		const closeTermsPopup = () => {
			showTerms.value = false;
		};

		const handleSubmit = async () => {
			error.value = null;
			isLoading.value = true;

			fullName.value = fullName.value.trim();
			email.value = email.value.trim();
			password.value = password.value.trim();

			closeTermsPopup();

			// Check if password is strong
			if (!Object.values(checks.value).every((check) => check)) {
				error.value = "Weak Password";
				return;
			}

			// Check if an invitation exists
			try {
				await checkIfInviteExist(email.value);
			} catch (e) {
				console.error("Error checking invitation: ", e);
				invitationExist.value = false;
			}

			if (invitationExist.value == true) {
				inviteUrl.value =
					process.env.VUE_APP_PORTAL +
					"/invitation/" +
					invitationId.value;

				popup.value = true;
				isLoading.value = false;
				return;
			}

			await signup(email.value, fullName.value, password.value, null);

			if (!error.value) {
				event("New Signup", { method: "Web email and password" });
				projectAuth.currentUser.sendEmailVerification().then(() => {
					console.log("verification email sent");
				});
				router.push({
					name: "AddCompany",
				});
			}
			isLoading.value = false;
		};

		const closePopup = () => {
			popup.value = false;
			isLoading.value = false;
		};

		return {
			fullName,
			email,
			password,
			checks,
			showChecks,
			checkPassword,
			handleSubmit,
			error,
			isLoading,
			popup,
			inviteUrl,
			closePopup,
			handleTermsClick,
			showTerms,
			closeTermsPopup,
			invitation,
		};
	},
};
</script>

<style lang="scss">
.left-text {
	width: 100%;
	padding: 140px 80px !important;

	a {
		font-weight: bold;
	}
}

.login-link {
	display: flex;
	width: 80%;
	flex-direction: column;
	gap: 0.5em;
	position: absolute;
	left: 25px;
	bottom: 10px;
	color: $rm-dark-grey;
	font-size: 0.8em;

	// text-align: center;
	a {
		color: $rm-dark-grey;
		font-weight: bold;
	}
}

.container-right {
	background-color: $rm-blue;
}
.logo {
	width: 180px;
	margin-bottom: 40px;
}
.password-hints {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	margin-top: 0.5em;
	margin-left: 0.5em;
	p {
		font-size: 0.8em;
		color: $rm-grey;
	}
	.success {
		color: $rm-success;
	}
}
.loading-tile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100px;
	width: 300px !important;
}
</style>
