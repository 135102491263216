<template>
	<div class="error-alert" v-if="alertDisplay" @click="clearError">
		<div class="message">
			<p>
				<b>Error!</b>
				{{ message }}
			</p>
		</div>

		<div class="clear-message">
			<fa class="clear" icon="times" />
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/runtime-core";
export default {
	props: ["message"],
	setup(props) {
		const alertDisplay = ref(true);
		const message = ref("");
		message.value = props.message;

		// click to clear error
		const clearError = () => {
			alertDisplay.value = false;
		};

		return { alertDisplay, clearError, message };
	},
};
</script>

<style lang="scss" scoped>
.error-alert {
	position: fixed !important;
	bottom: 2rem;
	left: 50%;
	display: flex;
	min-width: 300px;
	max-width: 500px;
	max-height: 30vh;
	overflow: auto;
	align-items: center;
	justify-content: center;
	background-color: $rm-danger;
	border-radius: 8px;
	box-shadow: $box-shadow;
	padding: 8px 5px 8px 15px;
	color: $rm-light-grey;
	z-index: 9999;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	cursor: pointer;
	.clear-message {
		width: 18px;
		.clear {
			display: none;
		}
	}
	.message {
		overflow: auto;
		padding: 0 10px 0 15px;
	}
	&:hover {
		.clear {
			display: inherit;
			color: white;
		}
	}
}
// .clear {
//     position: absolute;
//     top: 2px;
//     right: 4px;
//     cursor: pointer;
// }
</style>
