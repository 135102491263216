<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<div class="modal-header">
					<slot name="modal-header-content"></slot>
				</div>
				<div class="modal-body">
					<slot name="modal-body-content"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.modal-content {
	max-width: 500px;
}
</style>
