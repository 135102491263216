<template>
  <div class="vertical-center">
      <div id="center">
        <h1>404</h1>
        <h4>Page not found</h4>
      </div>
  </div>
</template>

<style lang="scss" scoped>
#center {
    text-align: center;
    h1 {
        font-size: 56px;
    }
    h4 {
        font-size: 34px;
    }
}
</style>