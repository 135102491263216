<template>
	<div class="right-nav flex-d">
		<div id="page-title">
			<h2>Checklists</h2>
		</div>
		<div class="page-links">
			<ul class="spacing">
				<li>
					<router-link :to="{ name: 'TemplatesDash' }"
						>Templates</router-link
					>
				</li>
				<li>
					<router-link
						:to="{
							name: 'ScheduledDash',
						}"
						:class="
							activeRoute === 'scheduled' ? 'active' : 'inactive'
						"
						>Scheduled</router-link
					>
				</li>
				<li>
					<router-link
						:to="{
							name: 'ActiveDash',
						}"
						:class="
							activeRoute === 'active' ? 'active' : 'inactive'
						"
						>Active</router-link
					>
				</li>
				<li>
					<router-link
						:to="{
							name: 'CompletedDash',
						}"
						:class="
							activeRoute === 'complete' ? 'active' : 'inactive'
						"
						>Completed</router-link
					>
				</li>
				<li v-if="employeeDoc && employeeDoc.role == 'Admin'">
					<router-link
						:to="{
							name: 'ArchivedDash',
						}"
						:class="
							activeRoute === 'archived' ? 'active' : 'inactive'
						"
						class="muted"
						>Archived</router-link
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useSessionStore } from "../../stores/sessionStore";
import { storeToRefs } from "pinia";

export default {
	name: "CheckDashNav",
	setup() {
		const route = useRoute();
		const activeRoute = ref("");
		const sessionStore = useSessionStore();
		const { employeeDoc } = storeToRefs(sessionStore);

		watchEffect(() => {
			if (route.path.endsWith("/scheduled")) {
				activeRoute.value = "scheduled";
			} else if (route.path.endsWith("/active")) {
				activeRoute.value = "active";
			} else if (route.path.endsWith("/completed")) {
				activeRoute.value = "complete";
			} else if (route.path.endsWith("/archived")) {
				activeRoute.value = "archived";
			} else {
				activeRoute.value = "";
			}
		});

		return { activeRoute, employeeDoc };
	},
};
</script>
