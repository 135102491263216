import { ref } from 'vue'

const useCollection = (collectionRef) => {
    const error = ref(null)
    const isLoading = ref(false)
    const documentId = ref(null)

    // add a new document
    const addDoc = async (doc) => {
        isLoading.value = true
        error.value = null

        try {
            let res = await collectionRef.add(doc)
            documentId.value = res.id
        }
        catch (err) {
            console.log(err.message)
            error.value = 'Could not add the document'
            isLoading.value = false
        }
        isLoading.value = false
    }

    // add a new document
    const setDoc = async (docId, doc) => {
        isLoading.value = true
        error.value = null

        try {
            await collectionRef.doc(docId).set(doc)
        }
        catch (err) {
            console.log(err.message)
            error.value = 'Could not add the document'
            isLoading.value = false
        }
        isLoading.value = false
    }

    const deleteDoc = async (doc) => {
        isLoading.value = true
        error.value = null

        try {
            await collectionRef.doc(doc).delete()
        } catch(err) {
            console.log(err.message)
            error.value = 'Could not delete the document'
            isLoading.value = false
        }
        isLoading.value = false
    }

    const updateDoc = async (docId, doc) => {
        isLoading.value = true
        error.value = null
      
        try {
          await collectionRef.doc(docId).update(doc)
        } catch (err) {
          console.log(err.message)
          error.value = 'Could not update the document'
          isLoading.value = false
        }
      
        isLoading.value = false
      }
      

    return { error, isLoading, documentId, setDoc, addDoc, deleteDoc, updateDoc }

}

export default useCollection