<template>
    <Alert :message="errorMessage" v-if="errorMessage" />
    <div class="vertical-center">
        <div class="form-container">
            <div id="logo">
                <img class="img-responsive" src="../../assets/rm-logo-blue.png" alt="logo" />
                <p>Manage People, Not Paperwork</p>
            </div>

            <form class="rm-form tile" v-if="success == null">
                <!-- <div class="form-header">Login to Risk Memo</div> -->
                <div class="p-1rem">
                    <div class="form-input">
                        <label for="email">Email:</label>
                        <input name="email" type="email" required v-model="email" />
                    </div>

                    <div class="form-submit">
                        <div v-if="isLoading">
                            <page-loading />
                        </div>
                        <button
                            class="btn btn-primary"
                            type="submit"
                            value="submit"
                            v-if="!isLoading"
                            @click="handleSubmit"
                        >Reset Password</button>
                    </div>
                    <div class="spacer-20"></div>
                    <div class="helper-text">
                        Back to
                        <router-link :to="{ name: 'Login'}">login page</router-link>.
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="success-message" v-if="success != null">
        <p>
            <b>{{success}}</b>
        </p>
        <p>
            After resetting your password, head back to the
            <router-link :to="{ name: 'Login'}">
                <b>login page</b>
            </router-link>
        </p>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { projectAuth } from "../../firebase/config";
import Alert from "../../components/Alert.vue";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";

export default {
    components: { Alert, PageLoading },
    name: "reset-password",
    setup() {
        useMeta({ title: "Password Reset" });
        // refs
        const email = ref("");
        const errorMessage = ref("");
        const isLoading = ref(false);
        const success = ref(null);

        const handleSubmit = async () => {
            isLoading.value = true;
            projectAuth
                .sendPasswordResetEmail(email.value)
                .then(() => {
                    isLoading.value = false;
                    success.value = "Password reset email sent!";
                })
                .catch((error) => {
                    isLoading.value = false;
                    errorMessage.value = error.message;
                    // ..
                });
        };

        return {
            email,
            handleSubmit,
            errorMessage,
            isLoading,
            success,
        };
    },
};
</script>

<style lang="scss">

#logo {
    margin-bottom: 40px;
    img {
        width: 180px;
    }
}
/*
#form-container {
    width: 30%;
    text-align: center;
}*/
</style>
