<template>
	<Alert :message="error" v-if="error" />
	<div class="success-message" v-if="copying">Copying checklist template</div>

	<!-- Navigation -->
	<div class="right-sub-nav fixed" v-else>
		<div class="nav-row">
			<div class="sub-nav-left">
				<div class="search">
					<fa class="search-icon" icon="search" />
					<input
						type="search"
						placeholder="Search Checklist Templates..."
						v-model="search"
					/>
				</div>
			</div>
			<div class="sub-nav-right">
				<router-link
					class="btn-link"
					:to="{ name: 'TemplateBuilder', params: { id: 'new' } }"
					v-if="role == 'Admin' || role == 'Contributor'"
				>
					<p>
						<span>
							<fa icon="tasks" />
						</span>
						&nbsp; New Template
					</p>
				</router-link>
				<router-link
					class="btn-link"
					:to="{ name: 'TemplateLibrary' }"
					v-if="role == 'Admin' || role == 'Contributor'"
				>
					<p>
						<span>
							<fa icon="book" />
						</span>
						&nbsp; Library
					</p>
				</router-link>
				<div
					v-if="role == 'Admin'"
					:class="{ muted: !isArchived, isArchived: isArchived }"
					@click="showArchived"
				>
					<p>
						<span>
							<fa v-if="!isArchived" icon="archive" />
							<fa v-else icon="undo" />
						</span>
						&nbsp;
						{{ isArchived ? "Active" : "Archived" }}
					</p>
				</div>
				<!-- <router-link
          v-if="role == 'Admin' || role == 'Contributor'"
          class="btn-link"
          :to="{ name: 'GenerateTemplate' }"
        >
          <p>
            <span>
              <fa icon="robot" />
            </span>
            &nbsp; AI Template
          </p>
        </router-link> -->
			</div>
		</div>
	</div>

	<!-- Loading -->
	<list-loading v-if="!templateData" />

	<div v-else class="list-container">
		<div class="no-list vertical-center" v-if="templateData.length == 0">
			<div class="icon">
				<fa icon="clipboard" />
			</div>
			<p>No checklist templates found</p>
		</div>

		<!-- Items List -->
		<div class="table-row-container" v-if="templateData.length > 0">
			<div v-for="(template, index) in templateData" :key="template.id">
				<div :class="{ tour_item: index === 0 }">
					<div
						class="table-row"
						v-if="template.isArchived == isArchived"
					>
						<div class="row-left">
							<div class="title no-img-title">
								{{ template.name }}
							</div>
							<div class="description">
								{{ template.description }}
							</div>
						</div>
						<div class="row-right">
							<div class="help-text">
								<!-- <p>Created by {{ template.ownerName }}</p> -->
								<p>
									Created by {{ getPerson(template.owner) }}
								</p>
							</div>
							<div class="actions" v-if="!isArchived">
								<div></div>

								<div
									class="actions-icon-btn"
									@click="editTemplate(template.id)"
									v-if="
										role == 'Admin' ||
										(user.uid == template.owner &&
											!isArchived)
									"
								>
									<fa icon="pen" />
								</div>

								<div
									class="actions-icon-btn"
									@click="handleCopyTemplate(template.id)"
									v-if="
										role == 'Admin' ||
										user.uid == template.owner
									"
								>
									<fa icon="copy" />
								</div>

								<div
									class="actions-icon-btn"
									@click="archiveTemplate(template.id)"
									v-if="role === 'Admin'"
								>
									<fa icon="archive" />
								</div>
							</div>
							<div class="actions" v-else>
								<div
									class="actions-icon-btn"
									@click="restoreTemplate(template.id)"
									v-if="role == 'Admin' && isArchived"
								>
									<fa icon="undo" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Delete Check -->
	<div class="alert" v-if="deleteAlert" @click="clearError">
		<span v-if="!errMessage">Are you sure you want to archive?</span>
		<span v-else>{{ errMessage }}</span>
		<button class="btn btn-success" @click="clearError">Cancel</button>
		<button class="btn btn-danger" @click="confirmDelete()">Archive</button>
	</div>

	<div class="alert" v-if="copyAlert" @click="clearError">
		<span v-if="!errMessage"
			>Are you sure you want to copy this template?</span
		>
		<span v-else>{{ errMessage }}</span>
		<button class="btn btn-danger" @click="clearError">Cancel</button>
		<button class="btn btn-success" @click="confirmCopy()">
			Create Copy
		</button>
	</div>
</template>

<script>
import { computed, onMounted, watchEffect } from "@vue/runtime-core";
import PageLoading from "../PageLoading.vue";
import { projectFirestore } from "../../firebase/config";
import account from "../../composables/account";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import useDocument from "../../composables/useDocument";
import templateCollection from "../../composables/templateCollection";
import Alert from "../Alert.vue";
import ListLoading from "../ListLoading.vue";
import employeeService from "../../composables/employeeService";
import getUser from "@/composables/getUser";
import getCollection from "../../composables/getCollection";

export default {
	components: { PageLoading, Alert, ListLoading },
	name: "TemplatesList",
	setup() {
		const { archiveTemplateById, copyTemplate } = templateCollection();
		const { fetchEmployeeNames, fetchInspector } = employeeService();
		const { role, companyId, userDoc } = account();
		const { user } = getUser();
		const router = useRouter();

		var templateIdToDelete = "";
		var templateIdToCopy = "";
		const isArchived = ref(false);
		const search = ref("");
		const error = ref(null);
		const templates = ref(null);
		const ownerEmployees = ref([]);
		const inspectorEmployees = ref([]);

		// set this to false so that before creating a new template it is always false
		localStorage.setItem("isNewTemplate", "false");

		const templatesRef = projectFirestore
			.collection("Template")
			.where("companyId", "==", companyId);

		const { documents } = getCollection(templatesRef);

		const getPerson = (id) => {
			const employee = ownerEmployees.value.find((emp) => emp.id === id);

			if (employee) {
				return employee.name;
			} else {
				const inspector = inspectorEmployees.value.find(
					(emp) => emp.id === id
				);
				if (inspector) {
					return inspector.name;
				} else {
					return "";
				}
			}
		};

		onMounted(async () => {
			const ownerEmployeeNames = await fetchEmployeeNames(companyId);

			ownerEmployeeNames.docs.forEach((doc) => {
				const employeeId = doc.id;

				const employeeFullName = doc.data().profile.fullName;

				ownerEmployees.value.push({
					id: employeeId,
					name: employeeFullName,
				});
			});

			const inspectorNames = await fetchInspector(companyId);

			inspectorNames.forEach((doc) => {
				const inspectorId = doc.id;

				const inspectorName = doc.name;

				inspectorEmployees.value.push({
					id: inspectorId,
					name: inspectorName,
				});
			});
		});

		watchEffect(async () => {
			if (documents.value) {
				templates.value = documents.value;
			}
		});

		const filteredTemplates = ref([]);

		const templateData = computed(() => {
			if (templates.value && templates.value.length && userDoc.value) {
				let data = [];
				filteredTemplates.value = [];

				templates.value.forEach((template) => {
					if (template.isArchived == isArchived.value) {
						filteredTemplates.value.push(template);
					}
				});

				// If admin show all templates
				if (role.value == "Admin") {
					data = filteredTemplates.value;
				} else {
					// Only show templates shared with user or the user's location
					filteredTemplates.value.forEach((template) => {
						if (
							template.users.findIndex(
								(x) => x == user.value.uid
							) != -1
						) {
							data.push(template);
						} else {
							var i = 0;
							if (userDoc.value.locations != null) {
								userDoc.value.locations.forEach((x) => {
									if (i == 0) {
										if (
											template.locations.findIndex(
												(y) => y == x
											) != -1
										) {
											data.push(template);
											i++;
										}
									}
								});
							}
						}
					});
				}

				// Then by search
				if (data && data.length) {
					data = data.filter((item) => {
						return (
							item.name
								.toLowerCase()
								.indexOf(search.value.toLowerCase()) != -1
						);
					});
				}
				return data;
			}
		});

		const showArchived = () => {
			if (isArchived.value) {
				isArchived.value = false;
			} else {
				isArchived.value = true;
			}
		};

		const editTemplate = (id) => {
			router.push({ name: "EditTemplateBuilder", params: { id: id } });
		};

		const deleteAlert = ref(false);
		const errMessage = ref(null);
		const copyAlert = ref(false);

		const archiveTemplate = (id) => {
			deleteAlert.value = true;
			templateIdToDelete = id;
		};

		const confirmDelete = async () => {
			try {
				await archiveTemplateById(templateIdToDelete);
			} catch (e) {
				errMessage.value = e.message;
			}

			if (!errMessage.value) {
				deleteAlert.value = false;
			}
		};

		const handleCopyTemplate = (id) => {
			copyAlert.value = true;
			templateIdToCopy = id;
		};

		const copying = ref(false);

		const confirmCopy = async () => {
			try {
				copying.value = true;
				await copyTemplate(templateIdToCopy, false, true);
				copying.value = false;
			} catch (e) {
				copying.value = false;
				errMessage.value = e.message;
			}

			if (!errMessage.value) {
				copyAlert.value = false;
			}
		};

		// click to clear error
		const clearError = () => {
			errMessage.value = null;
			deleteAlert.value = false;
			copyAlert.value = false;
		};

		const restoreTemplate = async (id) => {
			const docRef = projectFirestore.collection("Template").doc(id);

			const { updateDoc } = useDocument(docRef);

			await updateDoc({
				isArchived: false,
			});
		};

		return {
			templates,
			role,
			user,
			deleteAlert,
			errMessage,
			editTemplate,
			archiveTemplate,
			confirmDelete,
			clearError,
			templateData,
			handleCopyTemplate,
			copyAlert,
			confirmCopy,
			copying,
			error,
			search,
			isArchived,
			showArchived,
			restoreTemplate,
			getPerson,
		};
	},
};
</script>

<style lang="scss" scoped>
.muted {
	cursor: pointer;

	&:hover {
		color: $rm-pink;
	}
}

.isArchived {
	color: $rm-blue;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		color: $rm-pink;
	}
}

.search {
	input {
		//	width: 350px;
		width: 235px;

		@include respond(small) {
			//	width: 250px;
			width: 222px;
		}
		@include respond(larger) {
			width: 350px;
		}
		&::placeholder {
			font-size: 1em;
		}
	}
}

.nav-row {
	//	height: 100px;
	flex-direction: column;
	@include respond(small) {
		margin: 0.5em 0;
		height: 75px;
		flex-direction: row;
	}
	@include respond(medium) {
		padding: 0 1em;
		align-items: flex-start;
	}
	@include respond(large) {
		height: 50px;
		flex-direction: row;
		align-items: center;
		margin: 0;
	}
	@include respond(larger) {
		padding: 0 1.5em;
		align-items: center;
		justify-content: space-between;
	}
}

.sub-nav-right {
	margin-right: 0;
	gap: 7px;

	@include respond(large) {
		gap: 2em;
	}
}
.table-row .row-left {
	max-width: 50%;
	@include respond(small) {
		max-width: 60%;
	}
	@include respond(large) {
		max-width: 75%;
	}
}
.table-row .row-left .title {
	font-size: 12px;
	width: 100px;

	@include respond(small) {
		font-size: 14px;
		width: 175px;
	}
	@include respond(large) {
		width: 300px;
		font-size: 1em;
	}
}
.sub-nav-left {
	@include respond(medium) {
		padding: 0;
	}
	@include respond(large) {
		padding: 1em;
	}
}
.search {
	@include respond(medium) {
		margin-left: -0.2em;
	}
	@include respond(large) {
		margin-left: -1.2em;
	}
}
</style>
