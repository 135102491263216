import { projectFirestore } from "../firebase/config";

const employeeServiceCollection = () => {
  const fetchEmployeeNames = async (companyId) => {
    let ownerEmployeeNames = await projectFirestore
      .collection("companies")
      .doc(companyId)
      .collection("employees")
      .get();

    return ownerEmployeeNames;
  };

  const fetchEmployeeNameById = async (employeeId) => {
    const employeeDoc = await projectFirestore
      .collection("users")
      .doc(employeeId)
      .get();

    if (employeeDoc.exists) {
      const employeeName = employeeDoc.data().fullName;
      return employeeName;
    }

    return "";
  };

  const fetchInspector = async (companyId) => {
    const inspectorNames = await projectFirestore
      .collection("companies")
      .doc(companyId)
      .collection("inspector")
      .get();

    const inspectorIds = [];
    inspectorNames.forEach((doc) => {
      inspectorIds.push(doc.id);
    });

    const userArray = [];

    for (const inspectorId of inspectorIds) {
      const name = await fetchEmployeeNameById(inspectorId);
      userArray.push({ id: inspectorId, name: name });
    }

    return userArray;
  };

  return {
    fetchEmployeeNames,
    fetchEmployeeNameById,
    fetchInspector,
  };
};
export default employeeServiceCollection;
