<template>
	<div class="right-content">
		<div class="page-content">
			<div class="page-detail">
				<template-list />
			</div>
		</div>
	</div>
</template>

<script>
import TemplateList from "../../components/checklists/TemplateList.vue";
import { useMeta } from "vue-meta";

export default {
	name: "TemplatesDash",
	components: { TemplateList },
	setup() {
		useMeta({ title: "Checklist Templates" });
	},
};
</script>
