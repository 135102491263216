import { defineStore } from "pinia";
import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

export const useEquipmentStore = defineStore("equipment", () => {
    let unsubscribe = null;
    const equipment = ref([]);
    const loading = ref(false);

    const fetchEquipment = async (companyId, isArchived) => {
        loading.value = true;

        const equipmentRef = projectFirestore
            .collection("companies")
            .doc(companyId)
            .collection("equipment")
            .where("archived", "==", isArchived)
            .orderBy("name", "asc");

        unsubscribe = equipmentRef.onSnapshot(snapshot => {
            let equipmentList = [];
            snapshot.forEach(doc => {
                equipmentList.push({ id: doc.id, ...doc.data() });
            });
            equipment.value = equipmentList;
            loading.value = false;
        });
    }

    const resetEquipmentStore = () => {
        if (unsubscribe) {
            unsubscribe();
            equipment.value = [];
            unsubscribe = null;
        }
    }

    return {
        equipment,
        loading,
        fetchEquipment,
        resetEquipmentStore
    }
});