import { ref, watchEffect } from "vue";

const getCollection = (collectionRef) => {
  const documents = ref(null);
  const error = ref(null);

  const unsub = collectionRef.onSnapshot(
    (querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });

      documents.value = results;
    },
    (err) => {
      console.log("Error message: ", err.message);
      console.log("Error code: ", err.code);
      documents.value = null;
      error.value = "Could not fetch data";
    }
  );

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, documents };
};

export default getCollection;
