<template>
	<metainfo>
		<template v-slot:title="{ content }">{{
			content ? `${content} | Risk Memo` : `Risk Memo Web App`
		}}</template>
	</metainfo>
	<router-view />
</template>

<script>
import { useMeta } from "vue-meta";
import getUser from "./composables/getUser";
import { watch } from "vue";
import { useRouter } from "vue-router";

export default {
	setup() {
		useMeta({
			htmlAttrs: { lang: "en", amp: true },
			meta: [
				{
					name: "robots",
					content: "index, nofollow",
				},
			],
		});

		const { user } = getUser();
		const router = useRouter();

		watch(
			() => user.value,
			(newValue, oldValue) => {
				if (!newValue) {
					router.push({ name: "Login" });
				}
			}
		);

		document.title = "Risk Memo Health & Safety App";
	},
};
</script>

<style lang="scss"></style>
