import { ref } from "@vue/reactivity"

const useDocument = (collectionRef) => {
    const error = ref(null)
    const isLoading = ref(null)

    const updateDoc = async (updates) => {
        error.value = null
        isLoading.value = true

        try {
            await collectionRef.update(updates)
            isLoading.value = false
        } catch (err) {
            console.log(err.message)
            error.value = 'Could not update the document'
            isLoading.value = false
        }
    }

    return { error, isLoading, updateDoc }
}

export default useDocument

