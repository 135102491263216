import { defineStore } from "pinia";
import { watchEffect } from "vue";
import { projectFirestore, timestamp } from "../firebase/config";
import { ref } from "vue";
import getUser from "../composables/getUser";
import useCookies from "../composables/useCookies";
import useLogout from "../composables/useLogout";
import EnvUtils from "@/misc/env";

export const useSessionStore = defineStore("session", () => {
	const { setCookie, getCookie } = useCookies();
	const { logout } = useLogout();

	// Firestore connection
	const db = projectFirestore;
	const { user } = getUser();

	// document refs
	const settingsDoc = ref(null);
	const userDoc = ref(null);
	const employeeDoc = ref(null);
	const companyDoc = ref(null);

	// others
	const companyId = ref(null);
	const sessionStarted = ref(false);
	const hasEmployees = ref(undefined);
	const employeeCount = ref(null);

	const reset = () => {
		user.value = null;
		userDoc.value = null;
		companyId.value = null;
		employeeDoc.value = null;
		companyDoc.value = null;
		hasEmployees.value = undefined;
		employeeCount.value = null;
		sessionStarted.value = false;
	};

	// Holds the unsubscibe functions
	let settingsUnsubscribe = null;
	let employeeUnsubscribe = null;
	let userUnsubscribe = null;
	let companyUnsubscribe = null;
	let employeesUnsubscribe = null;

	const endSession = async () => {
		// Unsubscribe from all listeners
		if (settingsUnsubscribe) {
			settingsUnsubscribe();
			settingsUnsubscribe = null;
		}
		if (employeeUnsubscribe) {
			employeeUnsubscribe();
			employeeUnsubscribe = null;
		}
		if (userUnsubscribe) {
			userUnsubscribe();
			userUnsubscribe = null;
		}
		if (companyUnsubscribe) {
			companyUnsubscribe();
			companyUnsubscribe = null;
		}
		if (employeesUnsubscribe) {
			employeesUnsubscribe();
			employeesUnsubscribe = null;
		}

		// Reset the refs
		reset();

		// Clear the companyId cookie
		setCookie("rm_cId", "", "-1");

		// Logout from firebase auth
		await logout();
	};

	const updateUserActivity = async () => {
		var update = false;
		if (!userDoc.value.lastSeen) {
			update = true;
		} else {
			const now = Date.now();
			const lastSeenMillis = userDoc.value.lastSeen.seconds * 1000;

			// Calculate time difference in minutes
			const timeDifference = (now - lastSeenMillis) / (1000 * 60);
			console.log(
				`Time difference in minutes since last update: ${timeDifference}`
			);

			if (now - lastSeenMillis > 60 * 60 * 1000) {
				update = true;
			}
		}
		if (update) {
			// 1 hour in milliseconds
			console.log("Updating user activity");
			// Update user activity
			let updateActivityData = {
				lastSeen: timestamp(),
				lastPlatformUsed: "web",
			};

			if (userDoc.value && Array.isArray(userDoc.value.platforms)) {
				if (!userDoc.value.platforms.includes("web")) {
					updateActivityData.platforms = [
						...userDoc.value.platforms,
						"web",
					];
				}
			} else {
				// Initialize platforms as an array with "web" if it doesn't exist or isn't an array
				updateActivityData.platforms = ["web"];
			}

			await db
				.collection("users")
				.doc(user.value.uid)
				.update(updateActivityData);
		}
	};

	// Get the company settings document
	watchEffect(async () => {
		if (user.value) {
			try {
				if (settingsUnsubscribe) {
					settingsUnsubscribe();
				}

				const settingsRef = db
					.collection("settings")
					.doc(EnvUtils.SETTINGS_ID);
				settingsUnsubscribe = settingsRef.onSnapshot(
					(doc) => {
						if (doc.exists) {
							settingsDoc.value = { id: doc.id, ...doc.data() };
						} else {
							settingsDoc.value = null;
						}
					},
					(error) => {
						console.error(
							"Error listening for changes to company settings document:",
							error
						);
					}
				);
			} catch (error) {
				console.error(
					"Session store error fetching company settings document: ",
					error
				);
			}
		}
	});

	// Get the user doc to get the company ID
	// If there's no auth user then reset the store
	watchEffect(async () => {
		try {
			if (user.value) {
				// Start listening the the user document
				// First, clear any active listeners to this document
				if (userUnsubscribe) {
					userUnsubscribe();
				}

				const usersRef = db.collection("users").doc(user.value.uid);
				userUnsubscribe = usersRef.onSnapshot(
					async (doc) => {
						if (doc.exists) {
							userDoc.value = { id: doc.id, ...doc.data() };

							if (doc.data().companyId) {
								companyId.value = doc.data().companyId;
								setCookie("rm_cId", companyId.value, "14");
							}

							sessionStarted.value = true;
						} else {
							console.error(
								"User Authenticated but user document does not exist"
							);
						}
					},
					(error) => {
						console.error(
							"Error listening for changes to user document:",
							error
						);
					}
				);
			}
		} catch (error) {
			console.error(
				"Session store error fetching user document: ",
				error
			);
		}
	});

	watchEffect(async () => {
		try {
			if (user.value && companyId.value) {
				// Start listening for changes in the company document
				// This checks that there are no active listeners first
				if (companyUnsubscribe) {
					companyUnsubscribe();
				}

				const companyRef = db
					.collection("companies")
					.doc(companyId.value);
				companyUnsubscribe = companyRef.onSnapshot(
					(doc) => {
						if (doc.exists) {
							companyDoc.value = {
								id: doc.id,
								...doc.data(),
							};
						} else {
							companyDoc.value = null;
						}
					},
					(error) => {
						console.error(
							"Error listening for changes to company document:",
							error
						);
					}
				);

				// Listen to changes in the number of employees
				// Needed for redirecting the user to complete setup
				if (employeesUnsubscribe) {
					employeesUnsubscribe();
				}

				const employeesRef = companyRef.collection("employees");
				employeesUnsubscribe = employeesRef.onSnapshot(
					(snapshot) => {
						if (snapshot.docs.length > 0) {
							let activeEmployees = snapshot.docs.filter(
								(doc) => doc.data().isActive === true
							);
							employeeCount.value = activeEmployees.length;
							hasEmployees.value = true;
						} else {
							hasEmployees.value = false;
						}
					},
					(error) => {
						console.error(
							"Error listening for changes to employees collection:",
							error
						);
					}
				);

				// Start listening for changes in the employee document
				// This checks that there are no active listeners first
				if (employeeUnsubscribe) {
					employeeUnsubscribe();
				}

				const employeeRef = employeesRef.doc(user.value.uid);
				employeeUnsubscribe = employeeRef.onSnapshot(
					(doc) => {
						if (doc.exists) {
							employeeDoc.value = { id: doc.id, ...doc.data() };
						} else {
							employeeDoc.value = null;
						}
					},
					(error) => {
						console.error(
							"Error listening for changes to employee document:",
							error
						);
					}
				);
			}
		} catch (error) {
			console.error(
				"Session store error fetching company, employees or employee document: ",
				error
			);
		}
	});

	const accountSetup = ref(null);
	let updateActivity = true;

	// To instruct the route guards in router index.js
	const updateAccountSetup = async () => {
		accountSetup.value = null;
		let result = { completed: false, redirect: null };

		// Session started means there's an auth user and userDoc
		if (sessionStarted.value && companyId.value) {
			const companyRef = projectFirestore
				.collection("companies")
				.doc(companyId.value);

			const company = await companyRef.get();

			if (!company.exists) {
				result = { completed: true, redirect: "AddCompany" };
				accountSetup.value = result;
				return;
			}

			if (company.exists) {
				// Check for locations
				const locationsRef = companyRef.collection("locations");
				const locations = await locationsRef.get();

				if (locations.docs.length === 0) {
					// A location hasn't been created
					result = { completed: true, redirect: "AddCompany" };
					accountSetup.value = result;
					return;
				}

				// No longer needed as locations and employee profiles are created at the same time
				// Then check if there are employees
				// const employeesRef = companyRef.collection("employees");
				// const employees = await employeesRef.get();

				// if (employees.docs.length === 0) {
				// 	result = {
				// 		completed: true,
				// 		redirect: "AddProfile",
				// 	};
				// 	accountSetup.value = result;
				// 	return;
				// }

				// If no subscription then redirect to confirm subscription
				if (
					!company.data().subscription ||
					!company.data().subscription.status
				) {
					result = {
						completed: true,
						redirect: "Error",
					};
					accountSetup.value = result;
					return;
				}

				if (updateActivity) {
					await updateUserActivity();
					updateActivity = false;
				}

				// If there's no redirect then the account setup is complete
				result = { completed: true, redirect: null };
				accountSetup.value = result;
				return;
			}
		} else if (sessionStarted.value && !companyId.value) {
			// await the userDoc
			const userRef = projectFirestore
				.collection("users")
				.doc(user.value.uid);
			const doc = await userRef.get();

			// User will either have an invitation or be the owner
			if (doc.data().invitationId) {
				result = {
					completed: true,
					redirect: "AddEmployeeProfile",
					query: { id: userDoc.value.invitationId },
				};
				accountSetup.value = result;
				return;
			} else {
				result = { completed: true, redirect: "AddCompany" };
				accountSetup.value = result;
				return;
			}
		}
	};

	watchEffect(() => {
		if (user.value) {
			updateAccountSetup();
		}
	});

	return {
		companyId,
		user,
		settingsDoc,
		userDoc,
		employeeDoc,
		companyDoc,
		sessionStarted,
		accountSetup,
		endSession,
		updateAccountSetup,
		hasEmployees,
		employeeCount,
	};
});
