<template>
	<div class="container-scrollable">
		<Alert :message="error" v-if="error" />
		<div class="form-container">
			<div class="logo-container">
				<img
					class="logo"
					src="../../assets/rm-icon.png"
					alt="Risk Memo Logo Icon"
				/>
				<h1>Login to Risk Memo</h1>
			</div>
			<form class="rm-form tile" @submit.prevent="handleSubmit(false)">
				<div class="p-1rem" v-if="!isLoading">
					<div class="form-input">
						<input
							class="login-input"
							name="email"
							type="email"
							placeholder="jane@email.com..."
							required
							v-model="email"
						/>
					</div>

					<div class="form-input">
						<input
							name="password"
							type="password"
							placeholder="password..."
							required
							v-model="password"
						/>
					</div>

					<div class="form-submit">
						<div v-if="isLoading">
							<page-loading />
						</div>
						<button
							class="btn btn-primary"
							type="submit"
							value="submit"
							v-else
						>
							Sign in with password
						</button>
					</div>
					<div class="spacer-20"></div>
					<div class="helper-text">
						Forgotten your password?
						<router-link :to="{ name: 'password-reset' }"
							>click here</router-link
						>.
					</div>
				</div>
				<page-loading v-else />
			</form>
			<div class="spacer-20"></div>
			<form
				class="rm-form"
				@submit.prevent="handleSubmit(true)"
				v-if="!isLoading"
			>
				<button
					class="btn btn-outline flex"
					type="submit"
					value="submit"
				>
					<img
						class="img-responsive google-logo"
						src="../../assets/google.png"
						alt="google logo"
					/>
					Sign in with Google
				</button>
			</form>
			<div class="version">Version {{ appVersion }}</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLogin from "../../composables/useLogin";
import { useRoute, useRouter } from "vue-router";
import Alert from "../../components/Alert";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";
import { useSessionStore } from "../../stores/sessionStore";
import { useChecklistsStore } from "../../stores/checklistsStore";
import { useEquipmentStore } from "../../stores/equipmentStore";
import { onMounted, watchEffect, inject } from "vue";
import { storeToRefs } from "pinia";
import invitationCollection from "../../composables/invitationCollection";
import {
	projectFirestore,
	projectAuth,
	timestamp,
	addArrayItem,
} from "../../firebase/config";
import useBeacon from "../../composables/useBeacon";

export default {
	name: "Login",
	components: { Alert, PageLoading },
	setup() {
		useMeta({
			title: "Login to Risk Memo",
			description:
				"Login to the Risk Memo Checklist and Equipment Management App",
			link: [
				{ rel: "canonical", href: "https://app.riskmemo.com/login" },
			],
		});
		const error = ref(null);
		const { error: loginErr, isLoading, login, googleLogin } = useLogin();
		const router = useRouter();
		const route = useRoute();
		const sessionStore = useSessionStore();
		const checklistsStore = useChecklistsStore();
		const equipmentStore = useEquipmentStore();
		const { user } = storeToRefs(sessionStore);
		const { checkIfPendingInviteExist } = invitationCollection();

		// refs
		const email = ref("");
		const password = ref("");
		const isGoogle = ref(false);
		const appVersion = ref(process.env.PACKAGE_VERSION);

		const cryptojs = inject("cryptojs");
		const { beaconLogout } = useBeacon(cryptojs);

		onMounted(async () => {
			beaconLogout();
			checklistsStore.resetChecklistsStore();
			equipmentStore.resetEquipmentStore();
			sessionStore.endSession();
		});

		const checkAccountExistsAndRedirect = async () => {
			error.value = null;
			isLoading.value = true;

			try {
				const usersRef = projectFirestore.collection("users");

				const userDoc = await usersRef
					.where("email", "==", user.value.email)
					.get();

				if (!userDoc.empty) {
					// Always direct to dash - route guards handle everything else
					if (route.redirectedFrom) {
						router.push({ path: route.redirectedFrom.fullPath });
					} else {
						router.push({ name: "TemplatesDash" });
					}
					isLoading.value = false;
				} else {
					// User has an auth account but no user doc or invitation
					// They may have signed up with google without being invited
					// login with email and password won't get this far
					await projectAuth.currentUser.delete();
					error.value =
						"No users or invitations found for this email.";
					isLoading.value = false;
				}
			} catch (e) {
				console.error("Error fetching user: ", e);
				error.value =
					"Could not fetch user. Please try again later or contact us.";
				isLoading.value = false;
				return;
			}
		};

		const handleSubmit = async (google) => {
			error.value = null;
			isLoading.value = true;

			isGoogle.value = google;
			let inviteCheck = { error: null, invitationId: null };

			email.value = email.value.trim().toLowerCase();
			password.value = password.value.trim();

			if (isGoogle.value) {
				// for google, login first to get the email
				await googleLogin();

				if (loginErr.value) {
					error.value = loginErr.value;
					isLoading.value = false;
					return;
				}

				inviteCheck = await checkIfPendingInviteExist(user.value.email);
			} else {
				// for email, check for pending invites first
				inviteCheck = await checkIfPendingInviteExist(email.value);
			}

			if (inviteCheck.error) {
				error.value = inviteCheck.error;
				isLoading.value = false;
				return;
			}

			if (inviteCheck.invitationId) {
				// If there's a pending invitation, the auth user should not exist
				// They signed in with google without an account
				if (projectAuth.currentUser) {
					await projectAuth.currentUser.delete();
				}

				// Don't login, go to accept invite
				router.push({
					name: "AcceptInvite",
					params: { id: inviteCheck.invitationId },
				});
				isLoading.value = false;
				return;
			}

			// If not logged in with google, login with email and password
			if (!isGoogle.value) {
				await login(email.value, password.value);
			}

			if (loginErr.value) {
				error.value = loginErr.value;
				isLoading.value = false;
				return;
			}

			if (user.value) {
				console.log("Logged in user: ", user.value);
				checkAccountExistsAndRedirect();
			}
		};

		// watchEffect(() => {
		// 	if (sessionStarted.value || (user.value && !sessionStarted.value)) {
		// 		checkAccountAndRedirect();
		// 	}
		// });

		return {
			email,
			password,
			handleSubmit,
			error,
			isLoading,
			appVersion,
		};
	},
};
</script>

<style lang="scss" scoped>
.container-scrollable {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100vh;
	overflow: auto;
}

.logo-container {
	.logo {
		max-width: 50px;
	}
	h1 {
		font-weight: 400;
		font-size: large;
	}
	margin: 40px 0;
}

.rm-form {
	width: 100%;

	.form-title {
		h1 {
			font-size: 1.3em !important;
			margin-top: 10px;
			font-weight: 600 !important;
		}
	}

	@include respond(medium) {
		width: 50vw;
	}

	@include respond(large) {
		width: 30vw;
	}

	.form-input {
		input {
			&::placeholder {
				color: $rm-grey !important;
			}
		}
	}
}
.version {
	margin-top: 40px;
	font-size: 0.8em;
	color: $rm-grey;
}
.google-logo {
	width: 20px;
	margin-right: 10px;
}
</style>
