<template>
    <Sidebar />
    <div class="right-content">
        <div class="right-center">
            <div id="message">
                <h3>Hi {{firstName}}</h3>
                <br />
                <p>Your access to this company has been suspended. Please contact your manager if you think this was done by mistake.</p>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "../../components/Sidebar";
import account from "../../composables/account";
import { watchEffect } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useMeta } from "vue-meta";

export default {
    name: "AccessSuspended",
    components: { Sidebar },
    setup() {
        useMeta({ title: "Access Suspended" });
        const { userDoc, user } = account();
        const router = useRouter();

        watchEffect(() => {
            if (userDoc.value) {
                if (userDoc.value.isActive) {
                    router.push({ name: "TemplatesDash" });
                }
            }
        });

        const firstName = user.value.displayName.split(" ")[0];

        return { firstName };
    },
};
</script>

<style lang="scss" scoped>
#message {
    text-align: center;
    width: 45ch;
}
</style>