<template>
	<div class="page-loading">
		<div class="dot-pulse"></div>
	</div>
</template>

<script>
export default {
	name: "PageLoading",
};
</script>

<style lang="scss" scoped>
.page-loading {
	margin: 0 auto;
	height: min-content;
}
.dot-pulse {
	display: flex;
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: $rm-blue;
	color: $rm-blue;
	box-shadow: 9999px 0 0 -5px $rm-blue;
	animation: dotPulse 1.5s infinite linear;
	animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: $rm-blue;
	color: $rm-blue;
}

.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px $rm-blue;
	animation: dotPulseBefore 1.5s infinite linear;
	animation-delay: 0s;
}

.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px $rm-blue;
	animation: dotPulseAfter 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
	0% {
		box-shadow: 9984px 0 0 -5px $rm-blue;
	}
	30% {
		box-shadow: 9984px 0 0 2px $rm-blue;
	}
	60%,
	100% {
		box-shadow: 9984px 0 0 -5px $rm-blue;
	}
}

@keyframes dotPulse {
	0% {
		box-shadow: 9999px 0 0 -5px $rm-blue;
	}
	30% {
		box-shadow: 9999px 0 0 2px $rm-blue;
	}
	60%,
	100% {
		box-shadow: 9999px 0 0 -5px $rm-blue;
	}
}

@keyframes dotPulseAfter {
	0% {
		box-shadow: 10014px 0 0 -5px $rm-blue;
	}
	30% {
		box-shadow: 10014px 0 0 2px $rm-blue;
	}
	60%,
	100% {
		box-shadow: 10014px 0 0 -5px $rm-blue;
	}
}
</style>
