<template>
	<div id="nav">
		<div id="nav-header">
			<img src="@/assets/logo-transparent@1080.png" alt="logo" />

			<label for="check" class="hamburger">
				<i class="fa-solid fa-bars hamburger-close"></i>
				<i class="fa-solid fa-x hamburger-open"></i>
			</label>
		</div>
		<input type="checkbox" id="check" />

		<ul class="nav-links" v-if="showSidebar">
			<div v-if="isActive">
				<router-link to="/checklists">
					<li @click="hideSidebar">
						<fa class="nav-icon" icon="tasks" />Checklists
						<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
					</li>
				</router-link>

				<router-link to="/actions">
					<li @click="hideSidebar">
						<fa
							class="nav-icon"
							icon="exclamation-triangle"
						/>Actions
						<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
					</li>
				</router-link>

				<router-link :to="{ name: 'EquipmentDash' }">
					<li @click="hideSidebar">
						<fa class="nav-icon" icon="tools" />Equipment
						<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
					</li>
				</router-link>
				<router-link :to="{ name: 'People' }">
					<li
						v-if="role === 'Admin' || role == 'Contributor'"
						@click="hideSidebar"
					>
						<fa class="nav-icon" icon="users" />People
						<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
					</li>
				</router-link>

				<router-link :to="{ name: 'Locations' }">
					<li
						v-if="role === 'Admin' || role == 'Contributor'"
						@click="hideSidebar"
					>
						<fa class="nav-icon" icon="building" />Locations
						<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
					</li>
				</router-link>
				<router-link :to="{ name: 'Account' }">
					<li v-if="role === 'Admin'" @click="hideSidebar">
						<fa class="nav-icon" icon="cog" />Settings
						<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
					</li>
				</router-link>
			</div>

			<a href="https://help.riskmemo.com" target="new">
				<li>
					<div class="flex">
						<fa class="nav-icon" icon="info-circle" />Help Docs
					</div>
					<fa class="nav-arrow" icon="external-link-alt" />
				</li>
			</a>
			<router-link :to="{ name: 'Rewardful' }">
				<li>
					<div class="flex">
						<fa class="nav-icon" icon="gift" />Earn £60
					</div>
					<!-- <fa class="nav-arrow" icon="external-link-alt" /> -->
				</li>
			</router-link>
			<!-- <a href="https://risk-memo.getrewardful.com/signup" target="new">
                <li>
                    <div class="flex">
                        <fa class="nav-icon" icon="gift" />Earn £20
                    </div>
                    <fa class="nav-arrow" icon="external-link-alt" />
                </li>
            </a> -->

			<li class="signout" @click="handleClick">
				<fa class="nav-icon" icon="sign-out-alt" />Sign out
				<!-- <fa class="nav-arrow" icon="arrow-right" /> -->
			</li>
		</ul>
		<div class="version">
			{{ role }}: {{ currentUser.displayName }} <br />&#169; Risk Memo Ltd
			| v{{ appVersion }}
		</div>
	</div>
	<div
		class="warning"
		v-if="currentUser.emailVerified == false"
		v-bind:class="{ expanded: isExpanded }"
	>
		<p>
			<b>
				<div class="verify-title">
					<span>Email unverified!</span>
					<fa
						class="expand"
						icon="eye"
						@click="expand"
						v-if="!isExpanded"
					/>
					<fa class="expand" icon="times" @click="expand" v-else />
				</div>
			</b>
		</p>
		<div class="help-text" v-if="isExpanded && !emailSent">
			We've sent you a verification email. To send again
			<span class="send-link" @click="sendVerification">click here</span>
		</div>
		<div class="help-text" v-if="isExpanded && emailSent">
			Email verification sent to {{ currentUser.email }}
		</div>
	</div>
	<router-view />
</template>

<script>
import useLogout from "../composables/useLogout";
import useCookies from "../composables/useCookies";
import { computed, ref } from "@vue/runtime-core";
import { projectAuth } from "../firebase/config";
import "vue-next-select/dist/index.min.css";

import { onMounted, watchEffect, inject } from "vue";

import { storeToRefs } from "pinia";
import { useSessionStore } from "../stores/sessionStore";
import { useChecklistsStore } from "../stores/checklistsStore";

import useBeacon from "../composables/useBeacon";

export default {
	name: "sidebar-component",
	setup() {
		const sessionStore = useSessionStore();
		const checklistsStore = useChecklistsStore();
		const { employeeDoc, user } = storeToRefs(sessionStore);

		const { logout } = useLogout();
		const { setCookie } = useCookies();

		const currentUser = projectAuth.currentUser;
		const emailVerified = ref(undefined);
		const isExpanded = ref(false);
		const emailSent = ref(false);

		const showSidebar = ref(true);

		const appVersion = ref(process.env.PACKAGE_VERSION);

		const queryParams = new URLSearchParams(window.location.search);
		const welcome = ref(false);

		const cryptojs = inject("cryptojs");

		const { identify, welcomeMessage } = useBeacon(cryptojs);

		onMounted(() => {
			welcome.value = queryParams.get("welcome_message");
		});

		watchEffect(() => {
			if (employeeDoc.value) {
				identify();

				if (welcome.value) {
					welcomeMessage();
				}
			}
		});

		const role = computed(() => {
			if (employeeDoc.value) {
				return employeeDoc.value.role;
			}
		});

		const isActive = computed(() => {
			if (employeeDoc.value) {
				return employeeDoc.value.isActive;
			}
		});

		watchEffect(() => {
			if (user != null) {
				if (currentUser.emailVerified == true) {
					emailVerified.value = true;
				}

				if (currentUser.emailVerified == false) {
					emailVerified.value = false;
				}
			}
		});

		const hideSidebar = () => {
			if (window.innerWidth < 768) {
				if (showSidebar.value == true) {
					showSidebar.value = false;
				} else {
					showSidebar.value = true;
				}
			}
		};

		const expand = () => {
			if (isExpanded.value == false) {
				isExpanded.value = true;
			} else {
				emailSent.value = false;
				isExpanded.value = false;
			}
		};

		const sendVerification = () => {
			projectAuth.currentUser.sendEmailVerification().then(() => {
				emailSent.value = true;
			});
		};

		const handleClick = async () => {
			sessionStore.endSession();
			checklistsStore.resetChecklistsStore();
			setCookie("rm_cId", "", "-1");
			await logout();
		};

		return {
			handleClick,
			user,
			role,
			emailVerified,
			expand,
			isExpanded,
			emailSent,
			sendVerification,
			currentUser,
			hideSidebar,
			showSidebar,
			isActive,
			appVersion,
		};
	},
};
</script>

<style lang="scss" scoped>
@mixin respond($breakpoint) {
	@if ($breakpoint ==small) {
		@media (min-width: 600px) {
			@content;
		}
	}

	@if ($breakpoint ==medium) {
		@media (min-width: 800px) {
			@content;
		}
	}

	@if ($breakpoint ==large) {
		@media (min-width: 1100px) {
			@content;
		}
	}
}

.mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding: 2em;
	text-align: center;

	@include respond(medium) {
		display: none;
	}

	.desk-icon {
		margin-bottom: 3em;

		.mobile-alt {
			font-size: 3em;
		}
	}
}

$border-bottom: 1px solid $rm-light-grey;

.warning {
	position: absolute;
	bottom: 1.5em;
	margin: 0 auto;
	left: 0;
	right: 0;
	padding: 0.5em;
	border-radius: 4px;
	max-width: fit-content;
	height: auto;
	background-color: $rm-warning;
	color: white;
	z-index: 2;

	@include respond(medium) {
		width: auto;
		right: 1em;
	}

	.verify-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2em;
	}
}

.expand {
	cursor: pointer;
	margin-left: 5em;
}

.help-text {
	font-size: 1em;

	@include respond(medium) {
		font-size: 0.7em;
	}

	.send-link {
		font-weight: bold;
		cursor: pointer;

		&:hover {
			color: $rm-pink;
		}
	}
}

.expanded {
	padding: 1em 0.5em;

	@include respond(medium) {
		padding: 1em;
	}
}

#nav {
	&::-webkit-scrollbar {
		display: none;
	}

	//display: none;
	padding-top: 69px;

	@include respond(medium) {
		display: inherit;
		position: fixed;
		top: 0;
		left: 0;
		width: $nav-width;
		height: 100%;
		overflow: scroll;
		background-color: $rm-off-white;
		//padding-top: 69px;
	}

	/* @include respond(small){
        display: inherit;
        position: fixed;
        top: 0;
        left: 0;
        width: $nav-width;
        height: 100%;
        overflow: scroll;
        background-color: white;
    }*/
	.version {
		position: fixed;
		bottom: 0;
		background-color: $rm-off-white;
		width: 100%;
		padding: 5px 0;
		text-align: center;
		font-size: 0.5em;
		color: $rm-grey;
		left: 0;
		z-index: 1;

		@include respond(medium) {
			position: absolute;
		}
	}

	#nav-header {
		position: fixed;
		top: 0;
		display: flex;
		// justify-content: flex-start;
		justify-content: space-between;
		padding-left: 1.6em;

		width: 100vw;
		height: 70px;
		align-items: center;
		background-color: $rm-off-white;
		// border-bottom: $border-bottom;
		z-index: 1;

		@include respond(medium) {
			width: $nav-width;
		}

		img {
			width: 100%;
			max-width: 170px;
		}

		i {
			// display: none;
			//padding-left: 80px;
			font-size: 18px;
			cursor: pointer;
		}
	}

	#check {
		display: none;

		&:checked ~ ul {
			left: 0;
			z-index: 1;
			background-color: #ffffff;
		}
	}

	.hamburger {
		//   top: 0;
		//   display: flex;
		//    position: absolute;
		//  transform: translate(245px, 25px);
		//  align-items: center;
		display: block;
		font-size: 20px;
		z-index: 1;
		padding: 25px;

		@include respond(medium) {
			display: none;
		}
	}

	.hamburger-open {
		display: none;
	}

	ul {
		width: 100%;
		left: -100%;
		position: absolute;
		bottom: 0;
		top: 70px;
		transition: all 0.5s;

		@include respond(medium) {
			display: inline;
			left: 0;
		}
	}

	.hamburger-open {
		display: none;
	}

	.hamburger-close {
		display: inline;
	}

	.nav-links {
		margin: 0px;
		padding: 1em 0px;

		li {
			display: block;

			margin: 0;
			//padding: 1em;
			padding: 1.2em;

			.username {
				max-width: 11ch;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}

		.nav-icon {
			width: 30px;
			margin: 0 1em;
		}

		.nav-arrow {
			float: right;
			margin-top: 4px;
		}

		.signout {
			cursor: pointer;

			.nav-arrow {
				display: none;
			}

			&:hover {
				color: $rm-pink;
				font-weight: bold;

				.nav-arrow {
					display: block;
				}
			}
		}
	}
}
</style>
