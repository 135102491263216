<template>
  <Sidebar />
  <div class="right-content">
    <CheckDashNav />
  </div>
</template>

<script>
import CheckDashNav from "../../components/checklists/CheckDashNav.vue";
import Sidebar from "../../components/Sidebar.vue";

export default {
  name: "Checklists",
  components: {
    Sidebar,
    CheckDashNav,
  },
};
</script>

<style lang="scss"></style>
