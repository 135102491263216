import { watchEffect, ref } from "@vue/runtime-core";
import { useSessionStore } from "../stores/sessionStore";
import { storeToRefs } from "pinia";
import useCookies from "../composables/useCookies";

const account = () => {
	const error = ref(null);
	const sessionStore = useSessionStore();
	const { getCookie } = useCookies();
	const companyId = getCookie("rm_cId");

	const {
		user,
		employeeDoc: userDoc,
		companyDoc: company,
	} = storeToRefs(sessionStore);

	const role = ref();

	watchEffect(async () => {
		if (userDoc?.value && company?.value) {
			// Pass the role of the user
			role.value = userDoc.value.role;
		}
	});

	return {
		companyId,
		user,
		userDoc,
		error,
		role,
	};
};

export default account;
