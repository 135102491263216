import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import { useSessionStore } from "../stores/sessionStore";
import { storeToRefs } from "pinia";
import getUser from "../composables/getUser";

// Always load these
import Signup from "../views/onboarding/Signup.vue";
import Login from "../views/auth/Login.vue";
import NotFound from "../components/NotFound.vue";
import Error from "../views/Error.vue";
import Checklists from "../views/checklists/Checklists.vue";
import TemplatesDash from "../views/checklists/TemplatesDash.vue";

// Authentication
import AccessSuspended from "../views/auth/AccessSuspended.vue";
import VerifyEmail from "../views/auth/VerifyEmail.vue";
import PasswordReset from "../views/auth/PasswordReset.vue";
import AcceptInvite from "../views/auth/AcceptInvite.vue";

// Checklists
const ScheduledDash = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/ScheduledDash.vue"
	);
const ActiveDash = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/ActiveDash.vue"
	);
const CompletedDash = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/CompletedDash.vue"
	);
const ArchivedDash = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/ArchivedDash.vue"
	);
const TemplateBuilder = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/TemplateBuilder.vue"
	);
const TemplateLibrary = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/TemplateLibrary.vue"
	);
const ChecklistDetail = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/ChecklistDetail.vue"
	);
const MapView = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/MapView.vue"
	);
const ChecklistImageView = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/ChecklistImageView.vue"
	);
const GenerateTemplate = () =>
	import(
		/* webpackChunkName: "group-checklists" */ "../views/checklists/GenerateTemplate.vue"
	);

// onboarding
const AddCompany = () =>
	import(
		/* webpackChunkName: "group-onboarding" */ "../views/onboarding/AddCompany.vue"
	);
const AddLocation = () =>
	import(
		/* webpackChunkName: "group-onboarding" */ "../views/onboarding/AddLocation.vue"
	);
const AddProfile = () =>
	import(
		/* webpackChunkName: "group-onboarding" */ "../views/onboarding/AddProfile.vue"
	);
const ConfirmSubscription = () =>
	import(
		/* webpackChunkName: "group-onboarding" */ "../views/onboarding/ConfirmSubscription.vue"
	);
const AddEmployeeProfile = () =>
	import(
		/* webpackChunkName: "group-onboarding" */ "../views/onboarding/AddEmployeeProfile.vue"
	);

// Rewardful
const Rewardful = () =>
	import(
		/* webpackChunkName: "group-rewardful" */ "../views/account/Rewardful.vue"
	);

// Settings
const Account = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/Account.vue"
	);
const AccountDash = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/AccountDash.vue"
	);
const ManageTags = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/ManageTags.vue"
	);
const Subscription = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/subscription/Subscription.vue"
	);
const Upgrade = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/subscription/Upgrade.vue"
	);
const RenewSubscription = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/subscription/Renew"
	);
const SubscriptionError = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/subscription/Error"
	);
const Purchase = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/subscription/Purchase"
	);
const Success = () =>
	import(
		/* webpackChunkName: "group-settings" */ "../views/account/subscription/Success"
	);

// People
const People = () =>
	import(/* webpackChunkName: "group-people" */ "../views/people/People.vue");
const InvitationsDash = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/InvitationsDash.vue"
	);
const InspectorInvitationsDash = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/InspectorInvitationsDash.vue"
	);
const PeopleDash = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/PeopleDash.vue"
	);
const SuspendedDash = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/SuspendedDash.vue"
	);
const Profile = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/Profile.vue"
	);
const UpdateProfileForm = () =>
	import(
		/* webpackChunkName: "group-people" */ "../components/people/UpdateProfileForm.vue"
	);
const InviteModal = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/InviteModal.vue"
	);
const InviteInspectorModal = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/InviteInspectorModal.vue"
	);
const CancelSubscription = () =>
	import(
		/* webpackChunkName: "group-people" */ "../components/account/CancelSubscription.vue"
	);
const AcceptInspectorInvite = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/AcceptInspectorInvite.vue"
	);
const EditInspectorLocation = () =>
	import(
		/* webpackChunkName: "group-people" */ "../views/people/EditInspectorLocation.vue"
	);

// Locations
const Locations = () =>
	import(
		/* webpackChunkName: "group-locations" */ "../views/locations/Locations.vue"
	);
const LocationsDash = () =>
	import(
		/* webpackChunkName: "group-locations" */ "../views/locations/LocationsDash.vue"
	);
const AddNewLocation = () =>
	import(
		/* webpackChunkName: "group-locations" */ "../views/locations/AddNewLocation.vue"
	);
const LocationDetail = () =>
	import(
		/* webpackChunkName: "group-locations" */ "../views/locations/LocationDetail.vue"
	);
const AddLocationEmployee = () =>
	import(
		/* webpackChunkName: "group-locations" */ "../views/locations/AddLocationEmployee.vue"
	);
const AddLocationManager = () =>
	import(
		/* webpackChunkName: "group-locations" */ "../views/locations/AddLocationManager.vue"
	);

// Equipment
const Equipment = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/equipment/Equipment.vue"
	);
const EquipmentDash = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/equipment/EquipmentDash.vue"
	);
// const AddEquipment = () => import(/* webpackChunkName: "group-equipment" */ "../views/equipment/AddEquipment.vue");
const UpdateEquipmentForm = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../components/equipment/UpdateEquipmentForm.vue"
	);
const AddEditEquipmentNote = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../components/equipment/AddEditEquipmentNote.vue"
	);
const AddEditEquipmentAction = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../components/equipment/AddEditEquipmentAction.vue"
	);
const EquipmentDetail = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/equipment/EquipmentDetail.vue"
	);
const ArchivedEquipment = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/equipment/ArchivedEquipment.vue"
	);
const LinkEquipment = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/checklists/LinkEquipment"
	);
const LinkUser = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/checklists/LinkUsers"
	);
const LinkLocation = () =>
	import(
		/* webpackChunkName: "group-equipment" */ "../views/checklists/LinkLocations"
	);

// Actions
const Actions = () =>
	import(
		/* webpackChunkName: "group-actions" */ "../views/actions/Actions.vue"
	);
const ActionDetail = () =>
	import(
		/* webpackChunkName: "group-actions" */ "../views/actions/ActionDetail.vue"
	);

// Route guards before entry
import useCookies from "../composables/useCookies";
import { watch } from "vue";
import { projectAuth } from "../firebase/config";
const { getCookie } = useCookies();
const { user } = getUser();

const requireAuthAndCookie = (to, from, next) => {
	const cookie = getCookie("rm_cId");

	if (!cookie || !user.value) {
		console.error(`${to.fullPath}: AUTH & COOKIE CHECK FAILED`);
		return next({ name: "Login", query: { redirect: to.fullpath } });
	} else {
		return next();
	}
};

// require auth
const requireAuth = (to, from, next) => {
	if (!user.value) {
		console.error(`${to.fullPath}: AUTH CHECK FAILED`);
		return next({ name: "Login", query: { redirect: to.fullpath } });
	} else {
		return next();
	}
};

const routes = [
	// onboarding
	{
		path: "/signup",
		name: "Signup",
		component: Signup,
	},
	{
		path: "/addcompany",
		name: "AddCompany",
		component: AddCompany,
		beforeEnter: requireAuth,
	},
	{
		path: "/addlocation",
		name: "AddLocation",
		component: AddLocation,
		beforeEnter: requireAuth,
	},
	{
		path: "/addprofile",
		name: "AddProfile",
		component: AddProfile,
		beforeEnter: requireAuth,
	},
	{
		path: "/confirm-subscription",
		name: "ConfirmSubscription",
		component: ConfirmSubscription,
		beforeEnter: requireAuth,
	},
	{
		path: "/accept-invitation",
		name: "AddEmployeeProfile",
		component: AddEmployeeProfile,
		beforeEnter: requireAuth,
	},
	{
		path: "/invitation/:id",
		name: "AcceptInvite",
		component: AcceptInvite,
		props: true,
	},
	{
		path: "/inspectorinvitation/:companyId/inspector/:id",
		name: "AcceptInspectorInvite",
		component: AcceptInspectorInvite,
		beforeEnter: requireAuthAndCookie,
		props: true,
	},
	// Authentication
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/password-reset",
		name: "password-reset",
		component: PasswordReset,
	},
	{
		path: "/access-suspended",
		name: "AccessSuspended",
		component: AccessSuspended,
		beforeEnter: requireAuth,
	},

	// Subscription Related
	{
		path: "/renew-subscription",
		name: "RenewSubscription",
		component: RenewSubscription,
		beforeEnter: requireAuthAndCookie,
	},
	{
		path: "/subscription-error",
		name: "SubscriptionError",
		component: SubscriptionError,
		beforeEnter: requireAuthAndCookie,
	},
	{
		path: "/purchase",
		name: "Purchase",
		component: Purchase,
		beforeEnter: requireAuthAndCookie,
	},
	{
		path: "/success",
		name: "Success",
		component: Success,
	},
	{
		path: "/verify-email",
		name: "VerifyEmail",
		component: VerifyEmail,
		beforeEnter: requireAuth,
	},

	// Default view
	{
		path: "/",
		redirect: "/checklists",
	},
	// Errors
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: NotFound,
	},
	{
		path: "/error",
		name: "Error",
		component: Error,
	},
	// Rewardful
	{
		path: "/rewards",
		name: "Rewardful",
		component: Rewardful,
		beforeEnter: requireAuthAndCookie,
	},
	// Account settings

	{
		path: "/account",
		name: "Account",
		component: Account,
		redirect: "/account/settings",
		beforeEnter: requireAuthAndCookie,
		children: [
			{
				path: "subscription",
				name: "Subscription",
				component: Subscription,
				beforeEnter: requireAuthAndCookie,
				children: [
					{
						path: "cancel-subscription/:subscriptionId",
						name: "CancelSubscription",
						component: CancelSubscription,
						beforeEnter: requireAuthAndCookie,
						props: true,
					},
					{
						path: "upgrade",
						name: "Upgrade",
						component: Upgrade,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},

			{
				path: "settings",
				name: "AccountDash",
				component: AccountDash,
				beforeEnter: requireAuthAndCookie,
				children: [
					{
						path: "manage-tags",
						name: "ManageTags",
						component: ManageTags,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},
		],
	},

	// Equipment
	{
		path: "/equipment",
		name: Equipment,
		component: Equipment,
		children: [
			{
				path: "active",
				name: "EquipmentDash",
				component: EquipmentDash,
				beforeEnter: requireAuthAndCookie,
				children: [
					{
						path: "/equipment-edit/:id",
						name: "EquipmentEdit",
						component: UpdateEquipmentForm,
						beforeEnter: requireAuthAndCookie,
						props: true,
					},
				],
			},
			{
				path: ":id",
				name: "EquipmentDetail",
				component: EquipmentDetail,
				beforeEnter: requireAuthAndCookie,
				props: true,
				children: [
					{
						path: "add",
						name: "AddEditEquipmentNote",
						component: AddEditEquipmentNote,
						beforeEnter: requireAuthAndCookie,
					},
					{
						path: "add",
						name: "AddEditEquipmentAction",
						component: AddEditEquipmentAction,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},
			{
				path: "archived",
				name: "ArchivedEquipment",
				component: ArchivedEquipment,
				beforeEnter: requireAuthAndCookie,
			},
		],
	},

	// People
	{
		path: "/people",
		name: "People",
		component: People,
		redirect: "/people/active",
		children: [
			{
				path: "active",
				name: "PeopleDash",
				component: PeopleDash,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "suspended",
				name: "SuspendedDash",
				component: SuspendedDash,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "invitations",
				name: "InvitationsDash",
				component: InvitationsDash,
				beforeEnter: requireAuthAndCookie,
				children: [
					{
						path: "add",
						name: "InviteModal",
						component: InviteModal,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},
			{
				path: "inspectorinvitations",
				name: "InspectorInvitationsDash",
				component: InspectorInvitationsDash,
				beforeEnter: requireAuthAndCookie,
				children: [
					{
						path: "add",
						name: "InviteInspectorModal",
						component: InviteInspectorModal,
						beforeEnter: requireAuthAndCookie,
					},
					{
						path: ":id",
						name: "EditInspectorLocation",
						component: EditInspectorLocation,
						beforeEnter: requireAuthAndCookie,
						props: true,
					},
				],
			},

			{
				path: ":id",
				name: "Profile",
				component: Profile,
				beforeEnter: requireAuthAndCookie,
				props: true,
				children: [
					{
						path: "update",
						name: "UpdateProfileForm",
						component: UpdateProfileForm,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},
		],
	},

	// Locations
	{
		path: "/locations",
		name: "Locations",
		component: Locations,
		redirect: "/locations/active",
		children: [
			{
				path: "active",
				name: "Locations",
				component: LocationsDash,
				beforeEnter: requireAuthAndCookie,
				children: [
					{
						path: "add",
						name: "AddNewLocation",
						component: AddNewLocation,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},
			{
				path: ":id",
				name: "LocationDetail",
				component: LocationDetail,
				beforeEnter: requireAuthAndCookie,
				props: true,
				children: [
					{
						path: "addEmployee",
						name: "AddLocationEmployee",
						component: AddLocationEmployee,
						beforeEnter: requireAuthAndCookie,
					},
					{
						path: "addManager",
						name: "AddLocationManager",
						component: AddLocationManager,
						beforeEnter: requireAuthAndCookie,
					},
				],
			},
		],
	},

	// Checklists module
	{
		path: "/checklists",
		name: "Checklists",
		component: Checklists,
		redirect: "/checklists/templates",
		children: [
			{
				path: "scheduled",
				name: "ScheduledDash",
				component: ScheduledDash,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "active",
				name: "ActiveDash",
				component: ActiveDash,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "completed",
				name: "CompletedDash",
				component: CompletedDash,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "archived",
				name: "ArchivedDash",
				component: ArchivedDash,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "templates",
				name: "TemplatesDash",
				component: TemplatesDash,
				// Don't require cookie due to failed onboarding redirects
				beforeEnter: requireAuth,
			},
			{
				path: "library",
				name: "TemplateLibrary",
				component: TemplateLibrary,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "template-generator",
				name: "GenerateTemplate",
				component: GenerateTemplate,
				beforeEnter: requireAuthAndCookie,
			},
		],
	},

	{
		path: "/checklist/:id",
		name: "ChecklistDetail",
		component: ChecklistDetail,
		props: true,
		beforeEnter: requireAuthAndCookie,
		children: [
			{
				path: "map-view",
				name: "MapView",
				component: MapView,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "image-view",
				name: "ChecklistImageView",
				component: ChecklistImageView,
				beforeEnter: requireAuthAndCookie,
			},
		],
	},

	{
		path: "/template-builder/:id",
		name: "TemplateBuilder",
		component: TemplateBuilder,
		beforeEnter: requireAuthAndCookie,
		props: true,
		children: [
			{
				path: "linkequipment",
				name: "LinkEquipment",
				component: LinkEquipment,
				props: true,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "linkuser",
				name: "LinkUsers",
				component: LinkUser,
				props: true,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "linklocations",
				name: "LinkLocations",
				component: LinkLocation,
				props: true,
				beforeEnter: requireAuthAndCookie,
			},
		],
	},
	{
		path: "/edit-template-builder/:id",
		name: "EditTemplateBuilder",
		component: TemplateBuilder,
		props: true,
		beforeEnter: requireAuthAndCookie,
		children: [
			{
				path: "linkequipment",
				name: "LinkEquipment",
				component: LinkEquipment,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "linkuser",
				name: "LinkUsers",
				component: LinkUser,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "linklocations",
				name: "LinkLocations",
				component: LinkLocation,
				beforeEnter: requireAuthAndCookie,
			},
		],
	},
	{
		path: "/actions",
		name: "Actions",
		component: Actions,
		beforeEnter: requireAuthAndCookie,
		children: [
			{
				path: ":companyId/:collection/:id",
				name: "ActionDetail",
				component: ActionDetail,
				props: true,
				beforeEnter: requireAuthAndCookie,
			},
			{
				path: "edit",
				name: "AddEditEquipmentActions",
				component: AddEditEquipmentAction,
				beforeEnter: requireAuthAndCookie,
			},
		],
	},
];

// Create the router
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: "active",
	linkExactActiveClass: "exact-active",
});

// For google tag manager to track routes
trackRouter(router);

// Routes that bypass route guards
let exemptRoutes = [
	"/",
	"/login",
	"/signup",
	"/addcompany",
	"/addlocation",
	"/addprofile",
	"/accept-invitation",
	"/purchase",
	"/subscription-error",
	"/error",
	"/renew-subscription",
	"/confirm-subscription",
	"/success",
	"/verify-email",
	"/access-suspended",
	"/templates?welcome_message=true",
];

function isExemptRoute(path, exemptRoutes) {
	// Split the path into the base path and query string
	const [basePath, queryString] = path.split("?");

	// If the base path is '/templates', only return true if the query string is 'welcome_message=true'
	if (basePath === "/templates") {
		return queryString === "welcome_message=true";
	}

	// Split the base path into segments
	const pathSegments = basePath.split("/");

	return exemptRoutes.some((route) => {
		// Split the exempt route into the base route and query string
		const [baseRoute, routeQueryString] = route.split("?");

		// Split the base route into segments
		const routeSegments = baseRoute.split("/");

		// Check that every segment of the exempt route matches the corresponding segment of the path
		const segmentsMatch = routeSegments.every(
			(segment, index) => segment === pathSegments[index]
		);

		// If the exempt route has a query string, check that it matches the query string of the path
		const queryStringMatches = routeQueryString
			? routeQueryString === queryString
			: true;

		// Return true if the segments and query string both match
		return segmentsMatch && queryStringMatches;
	});
}

// Route guards after reaching the page - managed in session store
router.afterEach((to, from) => {
	const sessionStore = useSessionStore();
	const { employeeDoc, accountSetup } = storeToRefs(sessionStore);

	if (!isExemptRoute(to.fullPath, exemptRoutes)) {
		watch(
			() => accountSetup.value,
			(newValue, oldValue) => {
				// If the new value is null, do nothing
				if (newValue === null) {
					return;
				}

				// Redirect if the user hasn't completed their onboarding
				if (newValue.completed && newValue.redirect != null) {
					const routeName = newValue.redirect;
					const routeData = {};

					// Handle special cases with additional query or params
					if (routeName === "AddEmployeeProfile") {
						const paramName = "id";
						routeData.query = {
							[paramName]: newValue.query.id,
						};
					}
					router.push({ name: routeName, ...routeData });
					return;
				}

				// Redirect if the user hasn't verified their email
				// if (projectAuth.currentUser) {
				//     projectAuth.currentUser.reload().then(() => {
				//         if (!projectAuth.currentUser.emailVerified) {
				//             router.push({ name: "VerifyEmail" });
				//             return;
				//         }
				//     });
				// }

				// Redirect if employee has been susspended
				if (employeeDoc.value) {
					if (!employeeDoc.value.isActive) {
						if (to.fullPath !== "/access-suspended") {
							router.push({ name: "AccessSuspended" });
							return;
						}
					}
				}
			}
		);
	}
});

export default router;
