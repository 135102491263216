import { ref } from "@vue/reactivity";

const getDocumentOnce = async (collectionRef) => {
    const document = ref(null);
    const error = ref(null);

    await collectionRef.get().then(
        (doc) => {
            if (doc.data()) {
                document.value = { ...doc.data(), id: doc.id };
            } else {
                error.value = "Document does not exist...";
            }
        },
        (err) => {
            console.error("Error getting document: ", err);
            console.log("Error message: ", err.message);
            console.log("Error code: ", err.code);

            if (err.code == "unavailable") {
                error.value = "No internet! Unable to load new data.";
            }
        }
    );

    return { error, document };
};

export default getDocumentOnce;
