import { ref } from "vue";
import { projectAuth, projectFirestore, timestamp } from "../firebase/config";
import useCollection from "./useCollection";
import getCollection from "./getCollection";
import useCookies from "./useCookies";
import getUser from "./getUser";
import getDocumentOnce from "./getDocumentOnce";
import { serverTimestamp } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import locationCollection from "./locationCollection";
import { template } from "lodash";
import EnvUtils from "@/misc/env";

const templateCollection = () => {
	const error = ref(null);
	const isLoading = ref(false);
	const document = ref(null);
	const templateData = ref(null);
	const templates = ref(null);

	const { getCookie } = useCookies();
	const { getLocation, locations, err } = locationCollection();
	getLocation();

	const addTemplate = async (template) => {
		const templateRef = projectFirestore.collection("Template");
		try {
			const { addDoc, documentId } = useCollection(templateRef);
			await addDoc(template);
			document.value = documentId;
		} catch (err) {
			error.value = err.message;
		}
	};

	const getAllTemplates = async () => {
		try {
			const companyId = getCookie("rm_cId");
			let collectionRef = projectFirestore
				.collection("Template")
				.where("companyId", "==", companyId);
			const { documents: items } = getCollection(collectionRef);
			templates.value = items;
		} catch (err) {
			error.value = err.message;
		}
	};

	const copyTemplate = async (id, isExternalTemplate, addCopyText) => {
		try {
			// Get the template data and store it in a variable
			await getTemplate(id);
			const template = templateData.value;

			if (!template) {
				throw new Error("Template not found");
			}

			const newTemplate = { ...template };

			delete newTemplate.pages;

			for (const key in newTemplate) {
				if (newTemplate.hasOwnProperty(key)) {
					// Check if the property's value is undefined
					if (newTemplate[key] === undefined) {
						// Handle the undefined property. Options include:

						// Option 1: Remove the property
						// delete newTemplate[key];

						// Option 2: Set to a default value (example: setting to null or an empty string/array/object)
						// newTemplate[key] = "default value"; // Choose an appropriate default value

						// Option 3: Log it, throw an error, or any other action
						throw new Error(`Property ${key} is undefined`);
					}
				}
			}

			// if from library change the users and companyId
			if (isExternalTemplate) {
				let companyId = getCookie("rm_cId");
				newTemplate.companyId = companyId;
				let currentUser = projectAuth.currentUser;
				newTemplate.users = [currentUser.uid];
				newTemplate.owner = currentUser.uid;
				newTemplate.ownerName = currentUser.displayName;
				newTemplate.sharedCompletedChecklistWithPeople = [];
				newTemplate.locations = [];
			}

			if (addCopyText) {
				newTemplate.name = template.name + " (Copy)";
			}

			newTemplate.isArchived = false;
			newTemplate.addedToLibrary = false;
			newTemplate.updatedAt = timestamp();
			newTemplate.createAt = timestamp();
			const newTemplateId = uuidv4();
			newTemplate.id = newTemplateId;

			const newTemplateRef = projectFirestore
				.collection("Template")
				.doc(newTemplateId);

			const batch = projectFirestore.batch();

			batch.set(newTemplateRef, newTemplate);

			if (!template.pages || template.pages.length === 0) {
				throw new Error("Template pages not found");
			}

			template.pages.forEach(async (page) => {
				const pageId = uuidv4();
				const pageObj = {
					id: pageId,
					title: page.page.pageTitle,
					order: page.page.order,
				};

				const newPageRef = projectFirestore
					.collection("Template")
					.doc(newTemplateId)
					.collection("Pages")
					.doc(pageId);

				batch.set(newPageRef, pageObj);

				const sections = page.page.sections;

				if (sections != undefined) {
					sections.forEach((section) => {
						const sectionId = uuidv4();
						const sectionObj = {
							id: sectionId,
							title: section.section.sectionTitle,
							order: section.section.order,
							repeat: section.section.repeat,
						};

						const newSectionRef = projectFirestore
							.collection("Template")
							.doc(newTemplateId)
							.collection("Pages")
							.doc(pageId)
							.collection("Sections")
							.doc(sectionId);

						batch.set(newSectionRef, sectionObj);

						const questions = section.section.questions;

						if (questions != undefined) {
							questions.forEach((quest) => {
								// Check for undefined properties
								for (const key in quest) {
									if (quest.hasOwnProperty(key)) {
										if (quest[key] === undefined) {
											throw new Error(
												`Property ${key} is undefined`
											);
										}
									}
								}

								const questId = uuidv4();

								quest.questId = questId;

								const newQuestionRef = projectFirestore
									.collection("Template")
									.doc(newTemplateId)
									.collection("Pages")
									.doc(pageId)
									.collection("Sections")
									.doc(sectionId)
									.collection("Questions")
									.doc(questId);

								batch.set(newQuestionRef, quest);
							});
						}
					});
				}
			});
			await batch.commit();

			// templateData.value = template.value
		} catch (err) {
			console.error(err);
			error.value = err.message;
		}
	};

	// const createExampleTemplate = async (id) => {
	// 	try {
	// 		const template = ref(null);
	// 		const companyId = getCookie("rm_cId");
	// 		var templateId = "";
	// 		const { user } = getUser();

	// 		let doc = await projectFirestore
	// 			.collection("Template")
	// 			.doc(id)
	// 			.get();

	// 		template.value = {
	// 			companyId: companyId,
	// 			description: doc.data().description,
	// 			name: doc.data().name,
	// 			tags: doc.data().tags,
	// 			instruction: doc.data().instruction,
	// 			isSignatureRequired: doc.data().isSignatureRequired,
	// 			equipments: doc.data().equipments,
	// 			users: [user.value.uid],
	// 			owner: user.value.uid,
	// 			ownerName: "Risk Memo",
	// 			sharedCompletedChecklistWithPeople: [],
	// 			locations: [],
	// 		};
	// 		templateId = doc.id;

	// 		//get pages

	// 		let pageres = await projectFirestore
	// 			.collection("Template")
	// 			.doc(templateId)
	// 			.collection("Pages")
	// 			.orderBy("order")
	// 			.get();

	// 		var pages = [];

	// 		pageres.docs.map((data) => {
	// 			var page = {
	// 				page: {
	// 					id: data.data().id,
	// 					pageTitle: data.data().title,
	// 					order: data.data().order,
	// 				},
	// 			};
	// 			pages.push(page);
	// 		});
	// 		template.value.pages = pages;

	// 		for (const page of template.value.pages) {
	// 			var sections = [];
	// 			var pageid = page.page.id;

	// 			let sectionres = await projectFirestore
	// 				.collection("Template")
	// 				.doc(templateId)
	// 				.collection("Pages")
	// 				.doc(page.page.id)
	// 				.collection("Sections")
	// 				.orderBy("order")
	// 				.get();

	// 			sectionres.docs.map((secdata) => {
	// 				var section = {
	// 					section: {
	// 						id: secdata.data().id,
	// 						sectionTitle: secdata.data().title,
	// 						order: secdata.data().order,
	// 						repeat: secdata.data().repeat,
	// 					},
	// 				};
	// 				sections.push(section);
	// 			});

	// 			template.value.pages.map((pageData) => {
	// 				if (pageid == pageData.page.id) {
	// 					pageData.page.sections = sections;
	// 				}
	// 			});

	// 			for (const section of page.page.sections) {
	// 				let quesres = await projectFirestore
	// 					.collection("Template")
	// 					.doc(templateId)
	// 					.collection("Pages")
	// 					.doc(page.page.id)
	// 					.collection("Sections")
	// 					.doc(section.section.id)
	// 					.collection("Questions")
	// 					.orderBy("order")
	// 					.get();

	// 				var questions = [];
	// 				quesres.docs.map((quesData) => {
	// 					var question = {
	// 						questId: quesData.data().questId,
	// 						question: quesData.data().question,
	// 						isRequired: quesData.data().isRequired,
	// 						isTagRequired:
	// 							quesData.data().isTagRequired == undefined
	// 								? false
	// 								: quesData.data().isTagRequired,
	// 						isImageRequired:
	// 							quesData.data().isImageRequired == undefined
	// 								? false
	// 								: quesData.data().isImageRequired,
	// 						isMultipleChoice:
	// 							quesData.data().isMultipleChoice == undefined
	// 								? false
	// 								: quesData.data().isMultipleChoice,
	// 						type: quesData.data().type,
	// 						isLocationRequired:
	// 							quesData.data().isLocationRequired == undefined
	// 								? false
	// 								: quesData.data().isLocationRequired,
	// 						isSignatureRequired:
	// 							quesData.data().isSignatureRequired == undefined
	// 								? false
	// 								: quesData.data().isSignatureRequired,
	// 						choices:
	// 							quesData.data().choices == undefined
	// 								? []
	// 								: quesData.data().choices,
	// 						order: quesData.data().order,
	// 					};
	// 					questions.push(question);
	// 				});

	// 				template.value.pages.map((pageData) => {
	// 					if (pageid == pageData.page.id) {
	// 						pageData.page.sections.map((sectionData) => {
	// 							if (
	// 								sectionData.section.id == section.section.id
	// 							) {
	// 								sectionData.section.questions = questions;
	// 							}
	// 						});
	// 					}
	// 				});
	// 			}
	// 		}

	// 		var batch = projectFirestore.batch();
	// 		var tempId = uuidv4();
	// 		var docref = projectFirestore.collection("Template").doc(tempId);
	// 		const templateData = ref(null);
	// 		templateData.value = {
	// 			companyId: template.value.companyId,
	// 			description: template.value.description,
	// 			name: template.value.name,
	// 			tags:
	// 				template.value.tags == undefined ? [] : template.value.tags,
	// 			instruction: template.value.instruction,
	// 			isSignatureRequired: template.value.isSignatureRequired,
	// 			equipments: template.value.equipments,
	// 			users: template.value.users,
	// 			owner: template.value.owner,
	// 			ownerName: template.value.ownerName,
	// 			sharedCompletedChecklistWithPeople:
	// 				template.value.sharedCompletedChecklistWithPeople,
	// 			locations: template.value.locations,
	// 			updatedAt: timestamp(),
	// 			createAt: timestamp(),
	// 			isArchived: false,
	// 		};

	// 		try {
	// 			batch.set(docref, templateData.value);
	// 			await batch.commit();

	// 			template.value.pages.forEach(async (page) => {
	// 				batch = projectFirestore.batch();
	// 				var pageId = uuidv4();
	// 				var pageObj = {
	// 					id: pageId,
	// 					title: page.page.pageTitle,
	// 					order: page.page.order,
	// 				};

	// 				var docRef = projectFirestore
	// 					.collection("Template")
	// 					.doc(tempId)
	// 					.collection("Pages")
	// 					.doc(pageId); //automatically generate unique id
	// 				batch.set(docRef, pageObj);

	// 				var sections = page.page.sections;

	// 				if (sections != undefined) {
	// 					sections.forEach((section) => {
	// 						var sectionId = uuidv4();
	// 						var sectionObj = {
	// 							id: sectionId,
	// 							title: section.section.sectionTitle,
	// 							order: section.section.order,
	// 							repeat: section.section.repeat,
	// 						};

	// 						var docRef = projectFirestore
	// 							.collection("Template")
	// 							.doc(tempId)
	// 							.collection("Pages")
	// 							.doc(pageId)
	// 							.collection("Sections")
	// 							.doc(sectionId); //automatically generate unique id
	// 						batch.set(docRef, sectionObj);

	// 						var questions = section.section.questions;

	// 						if (questions != undefined) {
	// 							questions.forEach((quest) => {
	// 								var questId = uuidv4();
	// 								quest.questId = questId;

	// 								var docRef = projectFirestore
	// 									.collection("Template")
	// 									.doc(tempId)
	// 									.collection("Pages")
	// 									.doc(pageId)
	// 									.collection("Sections")
	// 									.doc(sectionId)
	// 									.collection("Questions")
	// 									.doc(questId); //automatically generate unique id
	// 								batch.set(docRef, quest);
	// 							});
	// 						}
	// 					});
	// 				}

	// 				await batch.commit();
	// 			});
	// 		} catch (err) {
	// 			error.value = err.message;
	// 			console.error(err.message);
	// 		}

	// 		// templateData.value = template.value
	// 	} catch (err) {
	// 		console.error(err.message);
	// 		error.value = err.message;
	// 	}
	// };

	const getAndProcessDefaultTemplates = async () => {
		// Assuming `settingsCollection` is an object that contains `defaultTemplates`
		console.log("Settings ID: ", EnvUtils.SETTINGS_ID);

		const settingsRef = projectFirestore
			.collection("settings")
			.doc(EnvUtils.SETTINGS_ID);
		const settingsRes = await settingsRef.get();

		const settings = { id: settingsRes.id, ...settingsRes.data() };

		const defaultTemplates = settings.defaultTemplates;

		let promises = [];

		for (const templateId of defaultTemplates) {
			promises.push(copyTemplate(templateId, true, false));
		}

		let results = await Promise.all(promises);

		return results;
	};

	const getTemplate = async (id) => {
		try {
			// Initialize template object
			const template = ref(null);

			// Get the template reference
			const templateRef = projectFirestore.collection("Template").doc(id);
			const pagesRef = templateRef.collection("Pages");
			const pagesQuery = pagesRef.orderBy("order");

			// Fetch template and pages in parallel
			const [templateDoc, pageDocs] = await Promise.all([
				templateRef.get(),
				pagesQuery.get(),
			]);

			template.value = { id: templateDoc.id, ...templateDoc.data() };

			template.value.pages = await Promise.all(
				pageDocs.docs.map(async (doc) => {
					const page = { id: doc.id, ...doc.data() };
					const sectionRef = pagesRef
						.doc(page.id)
						.collection("Sections");

					const sectionDocs = await sectionRef.orderBy("order").get();

					const Sections = await Promise.all(
						sectionDocs.docs.map(async (doc) => {
							const section = { id: doc.id, ...doc.data() };
							const questionDocs = await sectionRef
								.doc(doc.id)
								.collection("Questions")
								.orderBy("order")
								.get();

							const Questions = await Promise.all(
								questionDocs.docs.map(async (doc) => {
									const question = {
										id: doc.id,
										...doc.data(),
									};

									return question;
								})
							);

							return {
								section: {
									id: section.id,
									sectionTitle: section.title,
									order: section.order,
									repeat: section.repeat,
									questions: Questions,
								},
							};
						})
					);
					return {
						page: {
							id: page.id,
							pageTitle: page.title,
							order: page.order,
							sections: Sections,
						},
					};
				})
			);

			templateData.value = template.value;
		} catch (err) {
			console.error(err);
			error.value = err.message;
		}
	};

	const getTemplates = async (isArchived) => {
		const companyId = getCookie("rm_cId");

		let templatesRef = projectFirestore
			.collection("Template")
			.where("companyId", "==", companyId)
			.where("isArchived", "==", isArchived);

		let templates = [];

		const res = await templatesRef.get();

		res.docs.forEach((doc) => {
			const template = { id: doc.id, ...doc.data() };
			templates.push(template);
		});

		return { templates };
	};

	const updateTime = async (id) => {
		await projectFirestore
			.collection("Template")
			.doc(id)
			.update({ updatedAt: timestamp() });
	};

	const updateScheduleData = async (id, data) => {
		await projectFirestore.collection("Template").doc(id).update({
			IsScheduleChecklist: data.IsScheduleChecklist,
			scheduleChecklistType: data.scheduleChecklistType,
			scheduleDuration: data.scheduleDuration,
			scheduleDays: data.scheduleDays,
		});
	};

	const createAITemplate = async (aiTemplate) => {
		var templateId = uuidv4();
		var batch = projectFirestore.batch();

		const companyId = getCookie("rm_cId");

		const { user } = getUser();

		const templateRef = projectFirestore.collection("Template");

		const template = {
			companyId: companyId,
			description: aiTemplate.description,
			name: aiTemplate.title,
			tags: [],
			instruction: "",
			isSignatureRequired: false,
			equipments: [],
			users: [user.value.uid],
			owner: user.value.uid,
			ownerName: user.value.displayName,
			createAt: timestamp(),
			updatedAt: timestamp(),
			isArchived: false,
			sharedCompletedChecklistWithPeople: [user.value.uid],
			locations: [],
		};

		try {
			// Create the template
			let tempDocRef = templateRef.doc(templateId); //automatically generate unique id
			batch.set(tempDocRef, template);

			aiTemplate.pages.forEach((page, i) => {
				let index = 1 + i;
				let pageId = uuidv4();
				let pageObj = {
					id: pageId,
					title: page.page_title,
					order: index,
				};

				let pageDocRef = tempDocRef.collection("Pages").doc(pageId);
				batch.set(pageDocRef, pageObj);

				page.sections.forEach((section, i) => {
					let index = 1 + i;
					let sectionId = uuidv4();
					let sectionObj = {
						id: sectionId,
						title: section.section_title,
						order: index * 100,
						repeat: false,
					};

					let sectionDocRef = pageDocRef
						.collection("Sections")
						.doc(sectionId);
					batch.set(sectionDocRef, sectionObj);

					section.questions.forEach((question, i) => {
						let index = 1 + i;
						let questionId = uuidv4();
						let type;
						let isLocationRequired = false;
						let isSignatureRequired = false;
						let isImageRequired = false;

						let choices = [];
						if (
							question.input_type == "text" ||
							question.input_type == "textarea" ||
							question.input_type == "number" ||
							question.input_type == "email"
						) {
							type = "Text";
						} else if (question.input_type == "image") {
							type = "Image";
							isImageRequired = true;
						} else if (
							question.input_type == "date" ||
							question.input_type == "time"
						) {
							type = "DateTime";
						} else if (
							question.input_type == "checkbox" ||
							question.input_type == "radio"
						) {
							type = "Checkbox";
						} else if (question.input_type == "location") {
							type = "Location";
							isLocationRequired = true;
						} else if (question.input_type == "signature") {
							type = "Signature";
							isSignatureRequired = true;
						} else if (question.input_type == "select") {
							type = "MultiChoice";
							if (question.options) {
								question.options.forEach((option) => {
									choices.push({
										id: uuidv4(),
										text: option,
										color: "white",
									});
								});
							} else {
								question.options = ["Yes", "No", "N/A"];
								question.options.forEach((option) => {
									choices.push({
										id: uuidv4(),
										text: option,
										color: "white",
									});
								});
							}
						} else {
							type = "Text";
						}

						var questionObj = {
							questId: questionId,
							question: question.question,
							isRequired: false,
							isTagRequired: false,
							isImageRequired: isImageRequired,
							isMultipleChoice: false,
							type: type,
							isLocationRequired: isLocationRequired,
							isSignatureRequired: isSignatureRequired,
							choices: choices,
							order: index,
						};

						var questionDocRef = sectionDocRef
							.collection("Questions")
							.doc(questionId); //automatically generate unique id
						batch.set(questionDocRef, questionObj);
					});
				});
			});
			await batch.commit();
		} catch (e) {
			error.value = e.message;
			console.error(e);
		}

		return templateId;
	};

	const addPages = async (template, preview, id) => {
		const { user } = getUser();

		template.owner = user.value.uid;
		template.ownerName = user.value.displayName;
		template.createAt = timestamp();
		template.updatedAt = timestamp();
		var batch = projectFirestore.batch();

		var docref = projectFirestore.collection("Template").doc(id);
		batch.set(docref, template);

		try {
			preview.value.forEach((page) => {
				var pageObj = {
					id: page.page.id,
					title: page.page.pageTitle,
					order: page.page.order,
				};

				var pageId = page.page.id;

				var docRef = projectFirestore
					.collection("Template")
					.doc(id)
					.collection("Pages")
					.doc(pageId); //automatically generate unique id
				batch.set(docRef, pageObj);

				var sections = page.page.sections;

				if (sections != undefined) {
					sections.forEach((section) => {
						var sectionObj = {
							id: section.section.id,
							title: section.section.sectionTitle,
							order: section.section.order,
							repeat: section.section.repeat,
						};

						var docRef = projectFirestore
							.collection("Template")
							.doc(id)
							.collection("Pages")
							.doc(pageId)
							.collection("Sections")
							.doc(section.section.id); //automatically generate unique id
						batch.set(docRef, sectionObj);

						var questions = section.section.questions;

						if (questions != undefined) {
							questions.forEach((quest) => {
								var docRef = projectFirestore
									.collection("Template")
									.doc(id)
									.collection("Pages")
									.doc(pageId)
									.collection("Sections")
									.doc(section.section.id)
									.collection("Questions")
									.doc(quest.questId); //automatically generate unique id
								batch.set(docRef, quest);
							});
						}
					});
				}
			});
			batch.commit();
		} catch (err) {
			error.value = err.message;
		}
	};

	const updatePages = async (template, preview, id) => {
		var docref = projectFirestore
			.collection("Template")
			.doc(id)
			.update(template);

		var batch = projectFirestore.batch();

		try {
			preview.value.forEach((page) => {
				var pageId = page.page.id;
				if (page.page.isUpdate) {
					var pageObj = {
						id: page.page.id,
						title: page.page.pageTitle,
						order: page.page.order,
					};

					var docRef = projectFirestore
						.collection("Template")
						.doc(id)
						.collection("Pages")
						.doc(pageId); //automatically generate unique id
					batch.set(docRef, pageObj);
				}

				var sections = page.page.sections;

				sections.forEach((section) => {
					if (section.section.isUpdate) {
						var sectionObj = {
							id: section.section.id,
							title: section.section.sectionTitle,
							order: section.section.order,
							repeat: section.section.repeat,
						};

						var docRef = projectFirestore
							.collection("Template")
							.doc(id)
							.collection("Pages")
							.doc(pageId)
							.collection("Sections")
							.doc(section.section.id); //automatically generate unique id
						batch.set(docRef, sectionObj);
					}

					var questions = section.section.questions;

					questions.forEach((quest) => {
						if (quest.isUpdate) {
							var docRef = projectFirestore
								.collection("Template")
								.doc(id)
								.collection("Pages")
								.doc(pageId)
								.collection("Sections")
								.doc(section.section.id)
								.collection("Questions")
								.doc(quest.questId); //automatically generate unique id
							batch.set(docRef, quest);
						}
					});
				});
			});
			batch.commit();
		} catch (err) {
			error.value = err.message;
		}
	};

	const updateTemplate = async (template, id) => {
		if (
			template == undefined ||
			template == null ||
			id == undefined ||
			id == null
		) {
			console.error("Null or undefined template or id");
			error.value = "Template is undefined";
			return;
		}
		try {
			const templateRef = projectFirestore.collection("Template").doc(id);

			await templateRef.update(template);
		} catch (e) {
			error.value = e.message;
			console.error("Update Template Error: ", e);
		}
	};

	const updateSection = async (
		pageId,
		sectionId,
		sectionTitle,
		id,
		repeat
	) => {
		try {
			const res = await projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.update({ title: sectionTitle, repeat: repeat });
		} catch (err) {
			error.value = err.message;
		}
	};

	const updatePage = async (pageId, title, id) => {
		try {
			const res = await projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.update({ title: title });
		} catch (err) {
			error.value = err.message;
		}
	};

	const updateQuestion = async (
		templateId,
		pageId,
		sectionId,
		questionId,
		question
	) => {
		try {
			// Remove any undefined key-value pairs from the question
			for (const [key, value] of Object.entries(question)) {
				if (value === undefined) {
					delete question[key];
				}
			}

			const questionRef = projectFirestore
				.collection("Template")
				.doc(templateId)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.collection("Questions")
				.doc(questionId);

			await questionRef.update(question);
		} catch (err) {
			error.value = err.message;
			console.error(err);
			throw err;
		}
	};

	const addTemplatePage = async (page, pageId, id) => {
		var pageObj = {
			id: page.page.id,
			title: page.page.pageTitle,
			order: page.page.order,
		};

		try {
			const res = await projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.set(pageObj);
		} catch (err) {
			error.value = err.message;
		}
	};

	const addSectionToPage = async (templateId, pageId, data) => {
		await addSections(templateId, pageId, data.section.id, data);
		data.section.questions.forEach(async (question) => {
			await addQuestions(templateId, pageId, data.section.id, question);
		});
	};

	const addQuestions = async (templateId, pageId, sectionId, question) => {
		try {
			await projectFirestore
				.collection("Template")
				.doc(templateId)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.collection("Questions")
				.doc(question.questId)
				.set(question); //automatically generate unique id
		} catch (err) {
			error.value = err.message;
		}
	};

	const addSections = async (templateId, pageId, sectionId, section) => {
		var sectionObj = {
			id: section.section.id,
			title: section.section.sectionTitle,
			order: section.section.order,
			repeat: section.section.repeat,
		};

		try {
			await projectFirestore
				.collection("Template")
				.doc(templateId)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.set(sectionObj); //automatically generate unique id
		} catch (err) {
			error.value = err.message;
		}
	};

	const addPage = async (templateId, page) => {
		var batch = projectFirestore.batch();

		try {
			var pageObj = {
				id: page.page.id,
				title: page.page.pageTitle,
				order: page.page.order,
			};

			var pageId = page.page.id;

			var docRef = projectFirestore
				.collection("Template")
				.doc(templateId)
				.collection("Pages")
				.doc(pageId); //automatically generate unique id
			batch.set(docRef, pageObj);

			var sections = page.page.sections;

			if (sections != undefined) {
				sections.forEach((section) => {
					var sectionObj = {
						id: section.section.id,
						title: section.section.sectionTitle,
						order: section.section.order,
						repeat: section.section.repeat,
					};

					var docRef = projectFirestore
						.collection("Template")
						.doc(templateId)
						.collection("Pages")
						.doc(pageId)
						.collection("Sections")
						.doc(section.section.id); //automatically generate unique id
					batch.set(docRef, sectionObj);

					var questions = section.section.questions;

					if (questions != undefined) {
						questions.forEach((quest) => {
							var docRef = projectFirestore
								.collection("Template")
								.doc(templateId)
								.collection("Pages")
								.doc(pageId)
								.collection("Sections")
								.doc(section.section.id)
								.collection("Questions")
								.doc(quest.questId); //automatically generate unique id
							batch.set(docRef, quest);
						});
					}
				});
			}

			batch.commit();
		} catch (err) {
			error.value = err.message;
		}
	};

	const updateQuestions = () => {
		const templates = ref([]);
		const error = ref(null);

		const load = async (question, id, questionId) => {
			try {
				const res = await projectFireStore
					.collection("Template")
					.doc(id)
					.collection("Questions")
					.doc(questionId)
					.set(question);
			} catch (err) {
				error.value = err.message;
			}
		};

		return { templates, error, load };
	};

	const addLocation = () => {
		const templates = ref([]);
		const error = ref(null);

		const load = async (location, id) => {
			try {
				const res = await projectFireStore
					.collection("Template")
					.doc(id)
					.collection("Locations")
					.add(location);
			} catch (err) {
				error.value = err.message;
			}
		};

		return { templates, error, load };
	};

	const addEquipment = () => {
		const templates = ref([]);
		const error = ref(null);

		const load = async (equipment, id) => {
			try {
				const res = await projectFireStore
					.collection("Template")
					.doc(id)
					.collection("Equipments")
					.add(equipment);
			} catch (err) {
				error.value = err.message;
			}
		};

		return { templates, error, load };
	};

	const addUser = () => {
		const templates = ref([]);
		const error = ref(null);

		const load = async (user, id) => {
			try {
				const res = await projectFireStore
					.collection("Template")
					.doc(id)
					.collection("Users")
					.add(user);
			} catch (err) {
				error.value = err.message;
			}
		};

		return { templates, error, load };
	};

	const updatePageOrder = async (id, pageId, order) => {
		try {
			projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.update({ order: order });
		} catch (err) {
			error.value = err.message;
		}

		return { error };
	};

	const updateSectionOrder = async (id, pageId, sectionId, order) => {
		try {
			projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.update({ order: order });
		} catch (err) {
			error.value = err.message;
		}

		return { error };
	};

	const updateQuestionOrder = async (
		id,
		pageId,
		sectionId,
		questionId,
		order
	) => {
		try {
			projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.collection("Questions")
				.doc(questionId)
				.update({ order: order });
		} catch (err) {
			error.value = err.message;
		}

		return { error };
	};

	const deleteQuestion = async (id, pageId, sectionId, questionId) => {
		try {
			projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.collection("Questions")
				.doc(questionId)
				.delete();
		} catch (err) {
			error.value = err.message;
		}

		return { error };
	};

	const deleteSection = (id, pageId, sectionId) => {
		try {
			projectFirestore
				.collection("Template")
				.doc(id)
				.collection("Pages")
				.doc(pageId)
				.collection("Sections")
				.doc(sectionId)
				.delete();
		} catch (err) {
			error.value = err.message;
		}

		return { error };
	};

	const deletePage = async (id, pageId) => {
		try {
			const db = projectFirestore;
			const templateRef = db.collection("Template").doc(id);
			const batch = db.batch();

			const error = { value: null };

			// get the page to delete order number
			const pageRef = templateRef.collection("Pages").doc(pageId);
			const page = await pageRef.get();
			const pageOrderNumber = page.data().order;

			// get all the pages
			const query = templateRef.collection("Pages");
			const snapshot = await query.get();

			// Sort pages by order
			const sortedPages = snapshot.docs.sort(
				(a, b) => a.data().order - b.data().order
			);

			// Update page orders
			sortedPages.forEach((page, index) => {
				if (index >= pageOrderNumber) {
					let pageOrder = index;
					batch.update(page.ref, { order: pageOrder });
				}
			});

			batch.delete(pageRef);

			await batch.commit();
		} catch (e) {
			error = { value: e.message };
			console.error(e);
		}

		return { error };
	};

	const deleteTemplateTag = async (name) => {
		const companyId = getCookie("rm_cId");
		const tagdata = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("templatetags")
			.where("tag", "==", name)
			.get();
		if (tagdata.docs.length > 0) {
			tagdata.docs.forEach((doc) => {
				projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("templatetags")
					.doc(doc.id)
					.delete();
			});
		}
	};

	const getTemplateTags = async () => {
		const companyId = getCookie("rm_cId");
		//const collectionRef = projectFirestore.collection("companies").doc(companyId);
		// const {
		//     document: companyDoc,
		//     error: getDocErr,
		// } = await getDocumentOnce(collectionRef);

		// var tags = companyDoc.value.templateTags;

		var tags = [];
		const collectionRefdata = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("templatetags")
			.get();

		collectionRefdata.docs.forEach((doc) => {
			var tag = doc.data().tag;
			tags.push(tag);
		});

		return { tags };
	};

	const getUsers = async () => {
		const companyId = getCookie("rm_cId");
		const userRef = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("employees")
			.where("isActive", "==", true)
			.get();

		const userData = [];
		userRef.docs.map((user) => {
			userData.push({
				id: user.id,
				name: user.data().profile.fullName,
			});
		});

		return { userData };
	};

	const updateTemplateTags = async (tags) => {
		const companyId = getCookie("rm_cId");
		try {
			const tagdata = await projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("templatetags")
				.where("tag", "==", tags)
				.get();

			if (tagdata.docs.length == 0) {
				await projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("templatetags")
					.add({ tag: tags });
			}
		} catch (err) {
			console.error("Update template tags error:", err);
			error.value = err.message;
		}

		return { error };
	};

	const archiveTemplateById = async (id) => {
		projectFirestore.collection("Template").doc(id).update({
			isArchived: true,
			archivedDate: new Date(),
		});
	};

	return {
		getTemplates,
		createAITemplate,
		addPage,
		addTemplate,
		updateTemplate,
		addQuestions,
		updateQuestions,
		addLocation,
		addEquipment,
		addUser,
		addSections,
		addPages,
		document,
		error,
		addPages,
		templateData,
		getTemplate,
		updateSection,
		updatePage,
		updateQuestion,
		updatePages,
		getAllTemplates,
		templates,
		deleteQuestion,
		deleteSection,
		deletePage,
		updateQuestionOrder,
		updateSectionOrder,
		updatePageOrder,
		getTemplateTags,
		updateTemplateTags,
		getUsers,
		archiveTemplateById,
		addTemplatePage,
		addSectionToPage,
		updateTime,
		copyTemplate,
		deleteTemplateTag,
		// createExampleTemplate,
		getAndProcessDefaultTemplates,
		updateScheduleData,
	};
};

export default templateCollection;
