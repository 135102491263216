/**
 * Utility class for read environment variables.
 */

const EnvUtils = {
	EXAMPLE_CHECKLIST: process.env.VUE_APP_EXAMPLE_CHECKLIST,
	SETTINGS_ID: process.env.VUE_APP_SETTINGS_ID,
	INTERCOM_SECRET: process.env.VUE_APP_INTERCOM_SECRET,
	FIREBASE_API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
	FIREBASE_AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	FIREBASE_PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	FIREBASE_MESSAGING_SENDER_ID:
		process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	FIREBASE_APP_ID: process.env.VUE_APP_FIREBASE_APP_ID,
	FIREBASE_MEASUREMENT_ID: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
	APP_NAME: process.env.VUE_APP_NAME,
	GOOGLE_MAP_KEY: process.env.VUE_APP_GOOGLE_MAP_KEY,
	FIREBASE_API_URL: process.env.VUE_APP_FIREBASE_API_URL,
	PORTAL: process.env.VUE_APP_PORTAL,
	PORTAL_TOUR_ID: process.env.VUE_APP_PRODUCT_TOUR_ID,
	FIREBASE_REGION: process.env.VUE_APP_FIREBASE_REGION,
	ILOVEPDF_PUBLIC_KEY: process.env.VUE_APP_ILOVEPDF_PUBLIC_KEY,
	ILOVEPDF_SECRET_KEY: process.env.VUE_APP_ILOVEPDF_SECRET_KEY,
	REWARDFUL_API_SECRET: process.env.VUE_APP_REWARDFUL_API_SECRET,
	REWARDFUL_REWARDS_CAMPAIGN_ID:
		process.env.VUE_APP_REWARDFUL_REWARDS_CAMPAIGN_ID,
	SITEKEY: process.env.VUE_APP_SITEKEY,
	HELP_SCOUT: process.env.VUE_APP_HELP_SCOUT,
};

export default EnvUtils;
