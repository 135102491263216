import { ref } from "@vue/reactivity";
import {
	projectAuth,
	projectFirestore,
	timestamp,
	addArrayItem,
} from "../firebase/config";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";

// Import Firebase GoogleAuthProvider from compat
import firebase from "firebase/compat/app";
import { id } from "date-fns/locale";

const error = ref(null);
const isLoading = ref(false);
const { user } = getUser();

const usersRef = projectFirestore.collection("users");

const checkIfUserExists = async (email) => {
	isLoading.value = true;
	let response = { error: null, exists: false, id: null, user: null };

	try {
		const query = usersRef.where("email", "==", email);
		const res = await query.get();

		if (res.docs.length > 0) {
			response.exists = true;
			response.id = res.docs[0].id;
			response.user = res.docs[0].data();
		} else {
			response.exists = false;
		}
	} catch (e) {
		console.error(e);
		response.error =
			"Failed to check if user exists. Please try again or contact us";
	}

	isLoading.value = false;

	return response;
};

const addEmployeeProfile = async (email, fullName, companyId, locationId) => {
	try {
		const companyRef = projectFirestore
			.collection("companies")
			.doc(companyId);
		const employeeRef = companyRef
			.collection("employees")
			.doc(user.value.uid);

		// create an employee profile
		const profile = {
			profile: {
				fullName: fullName,
				jobTitle: "Team Member",
				email: email,
				profileImage: null,
			},
			role: "Employee",
			createdAt: timestamp(),
			isActive: true,
			manages: [],
			locations: [locationId],
		};

		const batch = projectFirestore.batch();

		batch.set(employeeRef, profile);

		// add the user to the location
		const locationRef = companyRef.collection("locations").doc(locationId);

		let update = {
			users: addArrayItem(user.value.uid),
		};

		batch.update(locationRef, update);

		await batch.commit();
	} catch (e) {
		console.log("Error adding employee profile: ", e);
		error.value = "Failed to create employee profile. Please contact us";
		return;
	}
};

/**
 * @param {string} email - The user's email
 * @param {string} fullName - The user's full name
 * @param {string} password - The user's password
 * @param {string} [invitationId] - The invitation ID (optional)
 * @returns {Promise<void>}
 */
const signup = async (email, fullName, password, invitationId = null) => {
	error.value = null;
	isLoading.value = true;

	try {
		await projectAuth.createUserWithEmailAndPassword(email, password);
	} catch (err) {
		console.error(err.message);
		error.value = err.message;
		isLoading.value = false;
		return;
	}

	// Check we have a firebase auth user
	if (!user.value) {
		console.log("user.value null");
		error.value = "Signup failed. Please try again or contact us";
		isLoading.value = false;
		return;
	}

	// Once signed in we have the reactive user object with getUser
	// We can now create a new user document in the users collection
	try {
		console.log("create user doc");
		const doc = {
			email: user.value.email,
			createdAt: timestamp(),
			invitationId: invitationId,
		};

		if (fullName) {
			// If signed in with email, we should have a full name
			projectAuth.currentUser.updateProfile({
				displayName: fullName,
			});
			doc.fullName = fullName;
		} else {
			// If signed in with email and no full name, use email name
			// This is a fallback that shouldn't happen
			const nameFromEmail = user.value.email.split("@")[0];
			projectAuth.currentUser.updateProfile({
				displayName: nameFromEmail,
			});
			doc.fullName = nameFromEmail;
		}

		console.log("user doc: ", doc);

		// If we have an invitation, we need to add the user to the company
		if (invitationId) {
			console.log("invitationId: ", invitationId);
			const invitationRef = projectFirestore
				.collection("invitations")
				.doc(invitationId);
			const invitationDoc = await invitationRef.get();

			if (!invitationDoc.exists) {
				console.error("Invitation does not exist");
				return;
			}

			const invitation = {
				id: invitationDoc.id,
				...invitationDoc.data(),
			};

			console.log("invitation", invitation);

			if (!invitation.companyUid || !invitation.location.locationId) {
				console.error("Invalid invitation");
				error.value = "Invalid invitation. Please contact us";
				return;
			}

			// Add the user to the company and location
			console.log("addEmployeeProfile");
			await addEmployeeProfile(
				doc.email,
				doc.fullName,
				invitation.companyUid,
				invitation.location.locationId
			);

			doc.companyId = invitation.companyUid;

			await invitationRef.update({ status: "Accepted" });
		}

		// Add the user to the users collection
		console.log("add user to users collection");
		await usersRef.doc(user.value.uid).set(doc);
		console.log("user added to users collection");
	} catch (err) {
		console.log("error adding user to users collection: ", err);
		error.value = "Failed to complete account setup. Please contact us";
		isLoading.value = false;
	}
};

const useSignup = () => {
	return { error, isLoading, checkIfUserExists, signup };
};

export default useSignup;
