<template>
	<!-- Table row loading -->
	<div class="list-container">
		<div class="table-row-container">
			<div class="table-row-shimmer shine"></div>
			<div v-if="showMessage" class="center-btn">
				<div class="shine message-text">
					Hold tight, loading is taking a little longer than usual...
				</div>
			</div>
			<div v-else class="table-row-shimmer shine"></div>
			<div class="table-row-shimmer shine"></div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
	name: "ListLoading",
	setup() {
		const showMessage = ref(false);

		onMounted(() => {
			setTimeout(() => {
				showMessage.value = true;
			}, 4000);
		});

		onBeforeUnmount(() => {
			showMessage.value = false;
		});

		return {
			showMessage,
		};
	},
};
</script>

<style lang="scss" scoped>
.table-row-shimmer {
	height: 55px;
	width: 100%;
	border-radius: $border-radius;
}
.message-text {
	width: 100%;
	color: $rm-grey;
	text-align: center;
	font-weight: bold;
	padding: 1em 2em;
	border-radius: $border-radius;
}
</style>
