<template>
    <div class="vertical-center">
        <div class="centered">
            <fa class="error-icon" icon="exclamation-circle" />
            <div class="spacer-40"></div>
            <h2>Sorry! Something went wrong</h2>
            <div class="spacer-20"></div>
            <p>
                If the problem continues, please
                <u>
                    <a href="mailto:support@riskmemo.com?subject=Risk Memo Error">contact us</a>
                </u>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Error",
};
</script>

<style lang="scss" scoped>
.centered {
    text-align: center;
}
.error-icon {
    font-size: 3em;
    color: $rm-grey;
}
</style>